
  import { defineComponent } from "vue";

  import { integer, minLength, maxLength } from "@/helpers/i18n-validators";

  import SimpleInput from "@/components/processComponents/SimpleInput.vue";

  export default defineComponent({
    name: "SimplePhone",

    extends: SimpleInput,

    validations() {
      return {
        value: {
          ...this.rules(),
          ...(this.attributes.min && { minValue: minLength(this.attributes.min) }),
          ...(this.attributes.max && { maxValue: maxLength(this.attributes.max) }),
          integer,
        },
      };
    },
  });
