export default {
  brokerageName: {
    label: "Nombre",
    id: "brokerageName",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder",
  },
  brokerageExternalId: {
    label: "Código",
    id: "brokerageExternalId",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder",
  },
  associatedRoles: {
    label: "Roles asociados",
    id: "associatedRoles",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder",
  },
  lastActivity: {
    label: "Última modificación",
    id: "lastActivity",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder",
  },
  actions: {
    label: "Acciones",
    id: "actions",
    colClasses: "col",
    headerClasses: "text-center fs-5 text-gray-700 fw-bolder",
    cellClasses: "d-flex justify-content-center align-items-center",
  },
};
