
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import VueTable from "@/components/VueTable.vue";

  interface Section {
    title: string;
    subtitle?: string;
    headers: string[];
    rows: string[];
  }

  export default defineComponent({
    name: "QuoteResultsRender",

    components: { VueTable },

    extends: BaseProcessComponent,

    computed: {
      sections(): Section[] {
        return (this.$attrs.modelValue || []) as Section[];
      },
    },
  });
