
  import { defineComponent } from "vue";

  import { required } from "@/helpers/i18n-validators";
  import moment from "moment";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath, hoursFormatter, mapperDateFormatter } from "@/helpers/utils";
  import { keys } from "lodash";

  export default defineComponent({
    name: "SimpleDate",

    extends: BaseProcessComponent,

    validations() {
      return {
        value: {
          ...(this.attributes.required && { required }),
          $autoDirty: true,
        },
      };
    },

    data() {
      return {
        customProperties: {} as any,
        options: { timeZone: "utc" },
        monthMapper: {
          ene: "JAN",
          feb: "FEB",
          mar: "MAR",
          abr: "APR",
          may: "MAY",
          jun: "JUN",
          jul: "JUL",
          ago: "AUG",
          sep: "SEP",
          oct: "OCT",
          nov: "NOV",
          dic: "DEC",
        } as any,
      };
    },

    computed: {
      value: {
        get() {
          let modelValue: any = this.$attrs.modelValue || this.attributes.value || null;
          modelValue = this.fixMonthDate(modelValue);
          return modelValue;
        },

        set(value: any) {
          if (!value) {
            this.$emit("update:modelValue", null);
            return;
          }

          if (this.isYearPicker) {
            this.$emit("update:modelValue", value);
            return;
          }

          if (this.isMonthPicker) {
            this.$emit(
              "update:modelValue",
              Array.isArray(value) ? this.monthYearFormat(value).split(",") : this.monthYearFormat(value)
            );
            return;
          }

          this.$emit("update:modelValue", Array.isArray(value) ? this.format(value).split(",") : this.format(value));
        },
      },

      dateFormat(): any {
        if (this.isMonthPicker) {
          return this.monthYearFormat;
        }

        if (this.isYearPicker) {
          return "";
        }

        return this.format;
      },

      format(): any {
        return (date: any) => {
          return Array.isArray(date)
            ? date.map((singleDate: any) => mapperDateFormatter(singleDate)).toString()
            : mapperDateFormatter(date);
        };
      },

      formattedBaseDate() {
        return Array.isArray(this.value)
          ? `${this.revertMonthDate(this.value[0])} , ${this.revertMonthDate(this.value[1])}`
          : this.revertMonthDate(this.value);
      },

      formattedHours(): any {
        return (date: any) => hoursFormatter(date, this.options);
      },

      isMonthPicker() {
        return this.attributes["month-picker"] || this.attributes["monthPicker"];
      },

      isYearPicker() {
        return this.attributes["year-picker"] || this.attributes["yearPicker"];
      },

      modelType(): string {
        let modelType: string = "dd-MMM-yyyy";
        if (this.isMonthPicker) {
          modelType = "MMM-yyyy";
        } else if (this.isYearPicker) {
          modelType = "yyyy";
        }

        return modelType;
      },

      monthYearFormat(): any {
        return (date: any) => {
          if (Array.isArray(date)) {
            return date
              .map((singleDate: any) => {
                return this.revertMonthDate(this.fixMonthDate(mapperDateFormatter(this.convertDateValue(singleDate))));
              })
              .toString();
          }

          return this.revertMonthDate(this.fixMonthDate(mapperDateFormatter(this.convertDateValue(date))));
        };
      },
    },

    watch: {
      attributes: {
        handler(newVal) {
          if (newVal) {
            this.setCustomProperties(newVal);
          }
        },
        deep: true,
      },
    },

    created() {
      this.setCustomProperties(this.attributes);
      if (this.attributes.hasOwnProperty("source") && this.applicationStore.application && !this.value) {
        this.$nextTick(() => {
          this.value = getValueByPath(
            { application: this.applicationStore.application },
            this.attributes.source.replace("$", "")
          );
        });
      } else if (this.attributes.value) {
        this.$emit("update:modelValue", this.attributes.value);
        this.componentViewMode = this.viewModes.visualization;
      }
    },

    methods: {
      convertDateValue(date: any): string {
        return date instanceof Date ? date.toISOString() : moment(date, "MMM-YYYY").toISOString();
      },

      setCustomProperties(attributes: any) {
        this.customProperties = { ...attributes };

        if (this.customProperties.min) {
          this.customProperties["min-date"] = moment(this.customProperties.min).toISOString();
        }

        if (attributes.max) {
          this.customProperties["max-date"] = moment(this.customProperties.max).toISOString();
        }

        if (attributes.initialValue) {
          const initialValue: any = attributes.initialValue;
          this.customProperties["start-date"] = this.isMonthPicker
            ? moment({ year: initialValue.year, month: initialValue.month })
            : moment({ year: initialValue.year, month: initialValue.month, day: initialValue.day });
        }

        if (attributes.timeDelta || attributes.dateRange) {
          this.setDateRanges(attributes, attributes.timeDelta ? "timeDelta" : "dateRange");
        }

        if (this.attributes.startDate) {
          this.customProperties["startDate"] = moment(this.attributes.startDate, "DD-MM-YYYY").toDate();
        }
      },

      setDateRanges(attributes: any, attributeKey: string) {
        const start: any = attributes[attributeKey].start;
        const end: any = attributes[attributeKey].end;
        let startDate: any = moment();
        let endDate: any = moment();

        if (attributeKey === "dateRange") {
          startDate = moment({ year: start.year, month: start.month - 1, day: start.day });
          endDate = moment({ year: end.year, month: end.month - 1, day: end.day });
        } else {
          if (attributes[attributeKey].centralValue) {
            const centralValue: any = attributes[attributeKey].centralValue;
            startDate = moment({ year: centralValue.year, month: centralValue.month - 1, day: centralValue.day });
            endDate = moment({ year: centralValue.year, month: centralValue.month - 1, day: centralValue.day });
          }

          startDate = this.setDeltaValues(startDate, start);
          endDate = this.setDeltaValues(endDate, end);
        }

        const minDate: any = this.isMonthPicker
          ? moment({ year: startDate.year(), month: startDate.month() })
          : startDate;
        const maxDate: any = this.isMonthPicker ? moment({ year: endDate.year(), month: endDate.month() }) : endDate;

        if (!this.isYearPicker) {
          this.customProperties["min-date"] = minDate;
          this.customProperties["max-date"] = maxDate;

          const startDateTime: number = this.customProperties["start-date"]?.valueOf();
          if (!startDateTime || startDateTime < minDate.valueOf() || startDateTime > maxDate.valueOf()) {
            this.customProperties["start-date"] = minDate;
          }
        }

        this.customProperties["year-range"] = [startDate.year(), endDate.year()];
      },

      setDeltaValues(currentDate: any, deltaValues: any) {
        currentDate.year(currentDate.year() + deltaValues.year);
        currentDate.month(currentDate.month() + deltaValues.month);
        currentDate.day(currentDate.month() + deltaValues.day);

        return currentDate;
      },

      fixMonthDate(date: any) {
        let tempDate: any = date;
        if (date) {
          if (Array.isArray(date)) {
            tempDate = date.map((singleDate: any) => {
              return this.fixMonthDate(singleDate);
            });
          } else {
            const arrayDate: any[] = `${date}`?.split("-");
            if (arrayDate?.length === 3 && this.monthMapper.hasOwnProperty(arrayDate[1].toLowerCase())) {
              tempDate = this.isMonthPicker
                ? `${this.monthMapper[arrayDate[1].toLowerCase()]}-${arrayDate[2]}`
                : `${arrayDate[0]}-${this.monthMapper[arrayDate[1].toLowerCase()]}-${arrayDate[2]}`;
            } else if (arrayDate?.length === 2 && this.monthMapper[arrayDate[0].toLowerCase()]) {
              tempDate = `${this.monthMapper[arrayDate[0].toLowerCase()]}-${arrayDate[1]}`;
            }
          }
        }

        return tempDate;
      },

      revertMonthDate(date: any): any {
        let tempDate: any = date;

        if (!date) {
          return tempDate;
        }

        if (Array.isArray(date)) {
          return date.map((singleDate: any) => {
            return this.revertMonthDate(singleDate);
          });
        }

        const arrayDate: any[] = `${date}`?.split("-");

        if (arrayDate?.length === 3) {
          let key: any = keys(this.monthMapper).find(
            (key: string) => this.monthMapper[key.toLowerCase()] == arrayDate[1]
          );
          if (key) {
            tempDate = this.isMonthPicker
              ? `${key.toUpperCase()}-${arrayDate[2]}`
              : `${arrayDate[0]}-${key.toUpperCase()}-${arrayDate[2]}`;
          }
        } else if (arrayDate?.length === 2) {
          let key: any = keys(this.monthMapper).find((key: string) => this.monthMapper[key] == arrayDate[0]);
          if (key) {
            tempDate = `${key.toUpperCase()}-${arrayDate[1]}`;
          }
        }

        return tempDate;
      },
    },
  });
