import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group mb-5" }
const _hoisted_2 = { class: "form-group mb-5" }
const _hoisted_3 = { class: "form-group mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_label = _resolveComponent("simple-label")!
  const _component_file_upload = _resolveComponent("file-upload")!
  const _component_simple_input = _resolveComponent("simple-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.attributes.class)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_simple_label, {
        id: "",
        type: "",
        "view-mode": _ctx.viewMode,
        attributes: _ctx.attributes.file_upload.label
      }, null, 8, ["view-mode", "attributes"]),
      _createVNode(_component_file_upload, {
        id: "",
        type: "",
        "view-mode": _ctx.viewMode,
        attributes: _ctx.attributes.file_upload.input,
        events: _ctx.events,
        modelValue: _ctx.data.document,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.document) = $event))
      }, null, 8, ["view-mode", "attributes", "events", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_simple_label, {
        id: "",
        type: "",
        attributes: _ctx.attributes.file_name.label
      }, null, 8, ["attributes"]),
      _createVNode(_component_simple_input, {
        id: "",
        type: "",
        attributes: _ctx.attributes.file_name.input,
        "view-mode": _ctx.viewMode,
        modelValue: _ctx.data.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.name) = $event))
      }, null, 8, ["attributes", "view-mode", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_simple_label, {
        id: "",
        type: "",
        attributes: _ctx.attributes.file_comments.label
      }, null, 8, ["attributes"]),
      _createVNode(_component_simple_input, {
        id: "",
        type: "",
        attributes: _ctx.attributes.file_comments.input,
        "view-mode": _ctx.viewMode,
        modelValue: _ctx.data.comments,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.comments) = $event))
      }, null, 8, ["attributes", "view-mode", "modelValue"])
    ])
  ], 2))
}