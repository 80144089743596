import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!
  const _component_form_templates = _resolveComponent("form-templates")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'no-click': _ctx.attributes.disabled })
  }, [
    _createVNode(_component_process_components_iterator, {
      components: _ctx.componentsToRender,
      "view-mode": _ctx.componentViewMode,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, ["components", "view-mode", "modelValue"]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.formTemplates)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.containerClasses)
            }, [
              (_openBlock(), _createBlock(_component_form_templates, _mergeProps(_ctx.formTemplates, {
                modelValue: _ctx.formData,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData) = $event)),
                key: _ctx.formTemplates.id,
                "index-position": _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
                parent: _ctx.attributes.name || _ctx.parent
              }), null, 16, ["modelValue", "index-position", "parent"]))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}