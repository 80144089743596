import { ComponentPublicInstance } from "vue/dist/vue";

export default class BaseError extends Error {
  options: any;

  constructor(message: string, options: any = null) {
    super(message);
    this.message = message;
    if (options) {
      this.options = options;
    }
  }

  render() {
    throw this; // NOSONAR;
  }

  report(instance?: ComponentPublicInstance | null, info?: string) {
    console.error(this.message, { instance, info });
  }
}
