
  import { defineComponent } from "vue";

  import Popper from "vue3-popper";

  export default defineComponent({
    name: "ThePagination",

    components: { Popper },

    props: {
      displayedPages: {
        type: Number,
        default: () => 3,
      },

      itemLabel: {
        type: String,
        default: () => "registros",
      },

      pageItems: {
        type: Number,
        default: () => 6,
      },

      page: {
        type: Number,
        default: () => 1,
      },

      totalItems: {
        type: Number,
        required: true,
      },
    },

    emits: ["pageChanged"],

    data() {
      return {
        selectedPage: 1 as number,
      };
    },

    computed: {
      canGoBackward(): boolean {
        return this.selectedPage > 1;
      },

      canGoForward(): boolean {
        return this.selectedPage < this.totalPages;
      },

      isFirstPage() {
        return this.selectedPage === 1;
      },

      isLastPage() {
        return this.selectedPage === this.totalPages;
      },

      maxEndNumber(): number {
        return this.selectedPage * this.pageItems;
      },

      pageItemEndNumber(): number {
        return this.maxEndNumber > this.totalItems ? this.totalItems : this.maxEndNumber;
      },

      pageItemStartNumber(): number {
        return this.maxEndNumber - (this.pageItems - 1);
      },

      partitionPageStart(): number {
        if (this.selectedPage === 1) {
          return 1;
        }

        if (this.selectedPage === this.totalPages) {
          return this.totalPages - (this.displayedPages - 1);
        }

        return this.selectedPage - 1;
      },

      partitionRange() {
        let range: number[] = [];
        for (
          let item = this.partitionPageStart;
          item <= Math.min(this.partitionPageStart + this.displayedPages - 1, this.totalPages);
          item++
        ) {
          if (item >= 1) {
            range.push(item);
          }
        }
        return range;
      },

      totalPages(): number {
        return Math.ceil(this.totalItems / this.pageItems);
      },
    },

    watch: {
      page(newVal) {
        if (newVal >= 1 && newVal <= this.totalPages) {
          this.selectedPage = newVal;
        }
      },

      totalPages(newVal) {
        if (this.page >= 1 && this.page <= newVal) {
          this.selectedPage = this.page;
        }
      },
    },

    created() {
      if (this.page > 1 && this.page <= this.totalPages) {
        this.selectedPage = this.page;
      }
    },

    methods: {
      goBackward() {
        this.goToPage(this.selectedPage - 1);
      },

      goFirstPage() {
        this.goToPage(1);
      },

      goForward() {
        this.goToPage(this.selectedPage + 1);
      },

      goLastPage() {
        this.goToPage(this.totalPages);
      },

      goToPage(pageNumber: number) {
        if (1 <= pageNumber && pageNumber <= this.totalPages) {
          this.selectedPage = pageNumber;
          this.$emit("pageChanged", { selectedPage: this.selectedPage });
        }
      },

      isCurrentPage(pageNumber: number): boolean {
        return this.selectedPage === pageNumber;
      },
    },
  });
