import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'no-click': _ctx.attributes.disabled })
  }, [
    _createVNode(_component_process_components_iterator, {
      components: _ctx.toRender,
      "view-mode": _ctx.componentViewMode,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, ["components", "view-mode", "modelValue"]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.selectedTemplate?.hasOwnProperty('components'))
          ? (_openBlock(), _createBlock(_component_process_components_iterator, {
              key: 0,
              "view-mode": _ctx.componentViewMode,
              components: _ctx.selectedTemplate.components,
              modelValue: _ctx.value[_ctx.selectedTemplate?.attributes.name],
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value[_ctx.selectedTemplate?.attributes.name]) = $event)),
              "parent-component-key": _ctx.selectedTemplate.attributes.name
            }, null, 8, ["view-mode", "components", "modelValue", "parent-component-key"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}