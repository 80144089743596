import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-control form-control-solid"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["placeholder", "data-maska"]
const _hoisted_4 = {
  key: 0,
  class: "mt-1 mb-0"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", null, _toDisplayString(_ctx.value), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", _mergeProps(_ctx.attributes, {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            class: ["form-control form-control-solid", { 'no-click': _ctx.attributes.disabled }],
            type: "text",
            placeholder: _ctx.attributes.placeholder || 'Escriba sus datos',
            "data-maska": _ctx.textTransformMask
          }), null, 16, _hoisted_3), [
            [_vModelText, _ctx.value],
            [_directive_maska, void 0, _ctx.maskaOptions]
          ]),
          (_ctx.v$.value.$errors.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.value.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: error.$uid,
                    class: "text-danger fw-bold mb-0"
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.serverErrors.length)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 1,
                class: _normalizeClass(["mb-0", { 'mt-1': !_ctx.v$.value.$errors.length }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `${_ctx.attributes.name}-error-${index}`,
                    class: "text-danger fw-bold mb-0"
                  }, [
                    _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_5)
                  ]))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ]))
  ]))
}