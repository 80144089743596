import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-check form-check-custom form-check-solid form-check-danger", { 'no-click': _ctx.isReadOnly || _ctx.attributes.disabled }])
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      class: "form-check-input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 512), [
      [_vModelCheckbox, _ctx.value]
    ])
  ], 2))
}