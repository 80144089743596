import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" }
const _hoisted_2 = { for: "items-per-page" }
const _hoisted_3 = { class: "text-gray-700 fs-4 fw-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, " Mostrando " + _toDisplayString(_ctx.showingFrom) + " a " + _toDisplayString(_ctx.showingTo) + " de " + _toDisplayString(_ctx.total) + " elementos ", 1)
    ])
  ]))
}