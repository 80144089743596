import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_date_picker, _mergeProps(_ctx.$attrs, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      "cancel-text": _ctx.cancelText,
      "enable-time-picker": _ctx.enableTimePicker,
      locale: _ctx.locale,
      "now-button-label": _ctx.nowButtonLabel,
      placeholder: _ctx.placeholder,
      "select-text": _ctx.selectText,
      "start-date": _ctx.startDate,
      "auto-apply": ""
    }), {
      "action-preview": _withCtx(({ value }) => []),
      _: 1
    }, 16, ["modelValue", "cancel-text", "enable-time-picker", "locale", "now-button-label", "placeholder", "select-text", "start-date"])
  ]))
}