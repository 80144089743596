import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-gray-500 fw-bold fs-5" }
const _hoisted_2 = { class: "bg-gray-200" }
const _hoisted_3 = {
  key: 0,
  style: { width: '30px' },
  id: "tableHeadRow"
}
const _hoisted_4 = { class: "form-check form-check-sm form-check-custom form-check-solid me-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", _hoisted_1, [
    _createElementVNode("tr", _hoisted_2, [
      (_ctx.checkboxEnabled)
        ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                class: "form-check-input",
                type: "checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectAll()))
              }, null, 544), [
                [_vModelCheckbox, _ctx.checked]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (column, i) => {
        return (_openBlock(), _createElementBlock("th", {
          key: i,
          class: "px-3 py-5",
          onClick: ($event: any) => (_ctx.onSort(column.columnLabel, column.sortEnabled)),
          style: _normalizeStyle({
            minWidth: column.columnWidth ? `${column.columnWidth}px` : '0',
            width: 'auto',
            cursor: column.sortEnabled ? 'pointer' : 'auto',
          })
        }, [
          _createTextVNode(_toDisplayString(column.columnName) + " ", 1),
          (_ctx.columnLabelAndOrder.label === column.columnLabel && column.sortEnabled)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                innerHTML: _ctx.sortArrow
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ], 12, _hoisted_5))
      }), 128))
    ])
  ]))
}