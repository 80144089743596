import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

export const DEFAULT_OPTIONS: SweetAlertOptions = {
  allowOutsideClick: false,
  position: "center",
};

export function swalSuccess(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    ...DEFAULT_OPTIONS,
    confirmButtonText: "Continuar",
    ...options,
    icon: "success",
  });
}

export function swalError(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    ...DEFAULT_OPTIONS,
    confirmButtonText: "Cerrar",
    ...options,
    icon: "error",
  });
}

export function swalWarning(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    ...DEFAULT_OPTIONS,
    confirmButtonText: "Continuar",
    ...options,
    icon: "warning",
  });
}

export function swalInfo(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    ...DEFAULT_OPTIONS,
    confirmButtonText: "Cerrar",
    ...options,
    icon: "info",
  });
}

export function swalQuestion(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
  return Swal.fire({
    ...DEFAULT_OPTIONS,
    showConfirmButton: false,
    showCancelButton: true,
    showDenyButton: true,
    cancelButtonText: "Cancelar",
    denyButtonText: "Si",
    ...options,
    icon: "question",
  });
}
