import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableContent = _resolveComponent("TableContent")!
  const _component_TableFooter = _resolveComponent("TableFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableContent, {
      onOnItemsSelect: _ctx.onItemSelect,
      onOnSort: _ctx.onSort,
      header: _ctx.header,
      data: _ctx.dataToDisplay,
      checkboxEnabled: _ctx.checkboxEnabled,
      checkboxLabel: _ctx.checkboxLabel,
      "empty-table-text": _ctx.emptyTableText,
      "sort-label": _ctx.sortLabel,
      "sort-order": _ctx.sortOrder,
      loading: _ctx.loading
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, name) => {
        return {
          name: name,
          fn: _withCtx(({ row: item }) => [
            _renderSlot(_ctx.$slots, name, { row: item })
          ])
        }
      })
    ]), 1032, ["onOnItemsSelect", "onOnSort", "header", "data", "checkboxEnabled", "checkboxLabel", "empty-table-text", "sort-label", "sort-order", "loading"]),
    _createVNode(_component_TableFooter, {
      onPageChange: _ctx.pageChange,
      "current-page": _ctx.currentPage,
      itemsPerPage: _ctx.itemsInTable,
      "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsInTable) = $event)),
      count: _ctx.totalItems,
      "items-per-page-dropdown-enabled": _ctx.itemsPerPageDropdownEnabled
    }, null, 8, ["onPageChange", "current-page", "itemsPerPage", "count", "items-per-page-dropdown-enabled"])
  ]))
}