
  import { defineComponent } from "vue";
  import { mapStores } from "pinia";

  import Popper from "vue3-popper";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import DocumentCard from "@/components/DocumentCard.vue";
  import documentsTableConfig from "@/core/config/DocumentsTableConfig";
  import Drivers from "@/helpers/clients/Drivers";
  import ThePagination from "@/components/ThePagination.vue";
  import { CardAppearances } from "@/components/base-card/CustomTypes";
  import { getValueByPath } from "@/helpers/utils";
  import { useMasterLayoutStore } from "@/stores/MasterLayoutStore";

  const identificationTypesMapper: any = {
    1: "dni",
    2: "rtn",
    3: "residence-card",
    9: "passport",
  };

  export default defineComponent({
    name: "DmClientProductDocuments",

    components: { ThePagination, DocumentCard, Popper },

    extends: BaseProcessComponent,

    data() {
      return {
        client: null as any,
        clientProduct: null as any,
        currentPage: 1 as number,
        driversClient: new Drivers(),
        documents: [] as any[],
        errorMessage: "" as string,
        gridView: true as boolean,
        isLoading: true,
        paginatedDocuments: [] as any[],
        perPageDocuments: 12 as number,
        query: "" as string,
        showErrorMessage: false as boolean,
        tableConfig: documentsTableConfig as any,
      };
    },

    computed: {
      ...mapStores(useMasterLayoutStore),

      application() {
        return this.applicationStore.application;
      },

      appearance(): string {
        return CardAppearances.row;
      },

      isMobile() {
        return this.masterLayoutStore.mobile;
      },

      recordsFoundTitle(): string {
        const recordsResultLabel: string =
          this.documents.length === 1 ? "registro encontrado" : "registros encontrados";

        return `${this.documents.length} ${recordsResultLabel}`;
      },

      totalDocuments() {
        return this.documents.length;
      },
    },

    watch: {
      documents: {
        handler() {
          this.paginateDocuments();
        },
        deep: true,
      },
    },

    created() {
      if (this.attributes.settings?.filters) {
        this.isLoading = true;
        this.loadDocuments()
          .then(() => {
            this.isLoading = false;
          })
          .catch((error: any) => {
            this.errorMessage = error.message;
            this.isLoading = false;
            this.showErrorMessage = true;
          });
      } else {
        this.errorMessage =
          "Se ha presentado un error en la configuración del componente de obtención de expediente" +
          " del cliente, por favor contacte al administrador del sistema";
        this.showErrorMessage = true;
      }
    },

    methods: {
      changePage(event: any) {
        this.currentPage = event.selectedPage;
        this.paginateDocuments();
      },

      async loadDocuments() {
        let vividocsConfig = this.getDataFromSources(
          { $application: this.applicationStore.application },
          this.attributes.settings
        );

        this.client = await this.getClientData(vividocsConfig.filters.clients);
        if (!this.client) {
          throw new Error("No se encontró en ViviDocs un cliente que coincida");
        }

        this.clientProduct = await this.getClientProductData(this.client.id, vividocsConfig.filters.client_products);
        if (!this.clientProduct) {
          throw new Error("No se encontró en ViviDocs un expediente que coincida");
        }

        for (const folder of this.clientProduct.product.folders) {
          this.documents = this.documents.concat(await this.getFolderDocuments(this.clientProduct.id, folder));
        }
      },

      async getFolderDocuments(clientProductId: string, folder: any, trail: string[] = []) {
        try {
          let folderDocuments: any[] = [];
          const folderDocumentsResponse = await this.driversClient.execute(
            process.env.VUE_APP_DOCUMENTS_MANAGER_DRIVER_ID,
            {
              resource: "ClientProducts",
              method: "get_folder_documents",
              arguments: {
                client_product_id: clientProductId,
                folder_id: folder.id,
                filters: {
                  path: folder.path,
                },
              },
            }
          );

          folderDocuments.push(
            ...folderDocumentsResponse.data.results.map((document: any) => ({
              folderId: folder.id,
              folderPath: folder.path,
              document: document,
              trail: trail.concat(folder.name),
            }))
          );

          for (const subFolder of folder.folders) {
            folderDocuments = folderDocuments.concat(
              await this.getFolderDocuments(clientProductId, subFolder, trail.concat(folder.name))
            );
          }

          return folderDocuments;
        } catch (_error: any) {}
      },

      async getClientData(filters: any) {
        const formattedFilters = JSON.parse(JSON.stringify(filters), (key, value) => {
          if (key === "identification_document") {
            return `${identificationTypesMapper[value.type]}:${value.number}`;
          } else if (typeof filters[key] === "object" && !Array.isArray(filters[key])) {
            let processedValue: string[] = [];
            Object.keys(value).forEach((valueKey) => processedValue.push(`${valueKey}:${value[valueKey]}`));
            return processedValue;
          }
          return value;
        });

        const clientSearchResponse = await this.driversClient.execute(process.env.VUE_APP_DOCUMENTS_MANAGER_DRIVER_ID, {
          resource: "Clients",
          method: "search",
          arguments: { filters: formattedFilters },
        });
        return clientSearchResponse.data.results[0] ?? null;
      },

      async getClientProductData(clientId: string, filters: { [key: string]: any }) {
        const formattedFilters = JSON.parse(JSON.stringify(filters), (key, value) => {
          if (typeof filters[key] === "object" && !Array.isArray(filters[key])) {
            let processedValue: string[] = [];
            Object.keys(value).forEach((valueKey) => processedValue.push(`${valueKey}:${value[valueKey]}`));
            return processedValue;
          }
          return value;
        });

        const clientProductSearchResponse = await this.driversClient.execute(
          process.env.VUE_APP_DOCUMENTS_MANAGER_DRIVER_ID,
          {
            resource: "ClientProducts",
            method: "search",
            arguments: {
              client_id: clientId,
              filters: formattedFilters,
            },
          }
        );
        return clientProductSearchResponse.data.results[0] ?? null;
      },

      paginateDocuments() {
        let lowerLimit = this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.perPageDocuments;
        let upperLimit =
          this.totalDocuments < this.currentPage * this.perPageDocuments
            ? this.totalDocuments
            : this.currentPage * this.perPageDocuments;
        this.paginatedDocuments = this.documents.slice(lowerLimit, upperLimit);
      },

      titleClasses(title: any): string {
        return `${title.colClasses} ${title.headerClasses}`;
      },

      getDataFromSources(context: any, sources: any): any {
        return JSON.parse(JSON.stringify(sources), (_key, value) => {
          if (typeof value === "string" && value.startsWith("$")) {
            return getValueByPath(context, value);
          }
          return value;
        });
      },
    },
  });
