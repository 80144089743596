import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0be6c117"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["true-value", "false-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["form-check form-switch form-check-custom form-check-solid form-check-danger", { 'no-click': _ctx.isReadOnly || _ctx.attributes.disabled }])
    }, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        type: "checkbox",
        class: "form-check-input",
        "true-value": _ctx.onTrueValue,
        "false-value": _ctx.onFalseValue
      }, null, 8, _hoisted_2), [
        [_vModelCheckbox, _ctx.value]
      ])
    ], 2)
  ]))
}