
  import { defineComponent } from "vue";

  import { maxLength, required } from "@/helpers/i18n-validators";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "SimpleTextarea",

    extends: BaseProcessComponent,

    validations() {
      return {
        value: {
          ...(this.attributes.required && { required }),
          ...(this.attributes.maxlength && { maxLength: maxLength(this.attributes.maxlength) }),
          $autoDirty: true,
        },
      };
    },

    data() {
      return {
        textTransform: {
          capitalize: (value: string): string => {
            return value.replace(/(^\w|\s\w)(\S*)/g, (_, firstChar, remainder) => firstChar.toUpperCase() + remainder);
          },

          lowercase: (value: string): string => {
            return value.toLowerCase();
          },

          uppercase: (value: string): string => {
            return value.toUpperCase();
          },

          none: (value: string): string => {
            return value;
          },
        } as any,
      };
    },

    computed: {
      validateTextTransformSetting(): boolean {
        if (!this.attributes?.settings?.text_transform) {
          return false;
        }

        return ["capitalize", "lowercase", "uppercase"].includes(this.attributes.settings.text_transform);
      },

      textTransformClass(): string {
        if (this.validateTextTransformSetting) {
          return `text-${this.attributes.settings.text_transform}`;
        }

        return "";
      },

      value: {
        get(): any {
          return this.$attrs.modelValue || this.attributes.value || "";
        },

        set(value: any) {
          if (value && this.validateTextTransformSetting) {
            this.$emit("update:modelValue", this.textTransform[this.attributes.settings.text_transform](value));
          } else {
            this.$emit("update:modelValue", value);
          }
        },
      },
    },

    created() {
      if (this.attributes.hasOwnProperty("value")) {
        this.value = this.attributes.value;
        this.componentViewMode = this.viewModes.visualization;
      } else if (this.attributes.hasOwnProperty("source") && this.applicationStore.application && !this.value) {
        this.$nextTick(() => {
          this.value = getValueByPath(
            { application: this.applicationStore.application },
            this.attributes.source.replace("$", "")
          );
        });
      }
    },
  });
