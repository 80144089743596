
  import { defineComponent } from "vue";

  import { helpers, minValue, maxValue, maxLength } from "@vuelidate/validators";
  import { numeric } from "@/helpers/i18n-validators";
  import { vMaska } from "maska";

  import SimpleInput from "@/components/processComponents/SimpleInput.vue";

  export default defineComponent({
    name: "DecimalInput",

    directives: { maska: vMaska },

    extends: SimpleInput,

    validations() {
      return {
        value: {
          ...this.rules(),
          minValue: helpers.withMessage("El valor no puede ser menor a 0", minValue(0)),
          ...(this.attributes.min && {
            minValue: helpers.withMessage(
              `El valor no puede ser menor a ${this.attributes.min}`,
              minValue(this.attributes.min)
            ),
          }),
          ...(this.attributes.max && {
            maxValue: helpers.withMessage(
              `El valor no puede ser mayor a ${this.attributes.max}`,
              maxValue(this.attributes.max)
            ),
          }),
          maxLength: helpers.withMessage(
            `La cantidad de cífras no puede exceder ${this.maxSafeDigits} digitos`,
            maxLength(this.value?.toString().includes(".") ? this.maxSafeDigits + 1 : this.maxSafeDigits)
          ),
          ...(this.attributes.maxLength && {
            maxLength: helpers.withMessage(
              `La cantidad de cífras no puede exceder ${this.attributes.maxLength} dígitos`,
              maxLength(
                this.value?.toString().includes(".") ? this.attributes.maxLength + 1 : this.attributes.maxLength
              )
            ),
          }),
          numeric,
        },
      };
    },

    data() {
      return {
        bindedObject: {
          masked: "",
          unmasked: "",
          completed: false,
        } as any,
        options: {
          preProcess: (val: any) => {
            let pattern = this.attributes.mask ? `[${this.attributes.mask.replace("{value}", "")},]` : ",";
            return val.replace(new RegExp(pattern, "g"), "");
          },

          postProcess: (val: any) => {
            if (!val) return "";

            const sub = 3 - (val.includes(".") ? val.length - val.indexOf(".") : 0);

            if (this.attributes.mask) {
              return this.attributes.mask.replace(
                "{value}",
                this.formattedNumber(val).slice(0, sub ? -sub : undefined)
              );
            }

            return this.formattedNumber(val).slice(0, sub ? -sub : undefined);
          },
        } as any,
        maxSafeDigits: 16,
        unmaskedValue: null as any,
      };
    },

    computed: {
      maxLengthWithMask() {
        const maskLength = this.attributes.mask ? this.attributes.mask.replace("{value}", "").length : 0;
        const punctuationCount = this.unmaskedValue ? (this.unmaskedValue.toString().match(/[,.]+/g) || []).length : 0;
        return this.attributes.maxLength
          ? this.attributes.maxLength + punctuationCount + maskLength
          : this.maxSafeDigits + punctuationCount + maskLength;
      },
      maskedValue(): string {
        if (this.value) {
          return this.formattedNumber(this.value);
        }

        return "";
      },

      value: {
        get() {
          let newValue: any = this.$attrs.modelValue;

          if ((newValue || newValue === 0) && this.unmaskedValue === null) {
            this.unmaskedValue = newValue;
          }

          return newValue;
        },

        set(_: any) {
          if (this.bindedObject.masked === "") {
            this.$emit("update:modelValue", this.bindedObject.masked);
          }

          if (Number(this.bindedObject.masked) || this.bindedObject.masked === "0") {
            this.$emit("update:modelValue", Number(this.bindedObject.masked));
          }
        },
      },
    },

    watch: {
      "bindedObject.masked"(newVal) {
        this.value = newVal;
      },
    },

    methods: {
      formattedNumber(value: any) {
        return Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value).replace(/[$]/g, "");
      },
    },
  });
