
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";

  export default defineComponent({
    name: "ConditionalSelectForm",

    extends: BaseProcessComponent,

    emits: ["update:modelValue"],

    data() {
      return {
        selectComponent: null as any,
        templates: [] as any[],
        toRender: [] as any[],
        selectedTemplate: null as any,
        selectedValue: "" as string,
      };
    },

    computed: {
      value: {
        get(): any {
          const value: any = this.$attrs.modelValue || {};
          this.selectedValue = value[this.selectComponent.attributes.name];
          return value;
        },

        set(value: any): any {
          if (this.selectedTemplate && Object.keys(value).includes(this.selectedTemplate.attributes.name)) {
            delete value[this.selectedTemplate.attributes.name];
          }

          const selectedTemplate = this.templates.find(
            (template: any) => template.attributes.for === value[this.selectComponent.attributes.name]
          );
          this.selectedValue = value[this.selectComponent.attributes.name];

          if (selectedTemplate && !value.hasOwnProperty(selectedTemplate.attributes.name)) {
            let templateProperty: any = {};
            templateProperty[selectedTemplate.attributes.name] = {};
            this.$emit("update:modelValue", { ...value, ...templateProperty });
          } else {
            this.$emit("update:modelValue", value);
          }
        },
      },
    },

    watch: {
      selectedValue(newValue: { codigo: string; detalle: string }) {
        this.selectedTemplate = this.getSelectedTemplate(newValue);
      },
    },

    created() {
      this.selectComponent = this.getTriggerSelect(this.components);
      this.selectComponent.attributes.key = `vs-${this.selectComponent.id}`;
      this.templates = this.getTemplates(this.components);
      this.toRender = this.components.filter((component: any) => component.type !== "form-templates");
    },

    methods: {
      getSelectedTemplate(forValue: { codigo: string; detalle: string } | null): any {
        return this.templates.find((template: any) => template.attributes.for === forValue?.codigo);
      },

      getTriggerSelect(components: any[]): any {
        let response: any = null;
        for (const component of components) {
          if (
            ["simple-select", "catalog-select"].includes(component.type) &&
            component.attributes.hasOwnProperty("hasTemplates") &&
            component.attributes.hasTemplates
          ) {
            response = component;
            return response;
          }

          response = this.getTriggerSelect(component.components);
          if (response) {
            return response;
          }
        }
        return response;
      },

      getTemplates(components: any[]): any {
        let response: any = null;
        for (const component of components) {
          if (component.type === "form-templates") {
            response = component.components;
            break;
          }

          response = this.getTemplates(component.components);
        }

        return response;
      },
    },
  });
