import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableItemsPerPageSelect = _resolveComponent("TableItemsPerPageSelect")!
  const _component_TablePagination = _resolveComponent("TablePagination")!

  return (_ctx.pageCount > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_TableItemsPerPageSelect, {
          itemsPerPage: _ctx.itemsCountInTable,
          "onUpdate:itemsPerPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsCountInTable) = $event)),
          "items-per-page-dropdown-enabled": _ctx.itemsPerPageDropdownEnabled,
          "total-pages": _ctx.pageCount,
          total: _ctx.count,
          "current-page": _ctx.page
        }, null, 8, ["itemsPerPage", "items-per-page-dropdown-enabled", "total-pages", "total", "current-page"]),
        _createVNode(_component_TablePagination, {
          "total-pages": _ctx.pageCount,
          total: _ctx.count,
          "per-page": _ctx.itemsPerPage,
          "current-page": _ctx.page,
          onPageChange: _ctx.pageChange
        }, null, 8, ["total-pages", "total", "per-page", "current-page", "onPageChange"])
      ]))
    : _createCommentVNode("", true)
}