
  import { defineComponent, PropType } from "vue";

  import CardBody from "@/components/base-card/card-partials/card-content/card-body/CardBody.vue";
  import CardHeader from "@/components/base-card/card-partials/card-content/card-header/CardHeader.vue";
  import CardFooter from "@/components/base-card/card-partials/card-content/card-footer/CardFooter.vue";
  import CardRow from "@/components/base-card/card-partials/card-content/card-body/CardRow.vue";
  import { CardAppearances, Component, Header } from "@/components/base-card/CustomTypes";

  export default defineComponent({
    name: "VueCard",

    components: { CardFooter, CardBody, CardHeader, CardRow },

    props: {
      appearance: {
        type: String,
        required: false,
        default: () => CardAppearances.card,
      },
      body: {
        type: Object as PropType<{ [key: string]: Component }>,
        required: true,
      },
      header: {
        type: Object as PropType<Header>,
        required: false,
      },

      config: {
        type: Object as PropType<{ [key: string]: any } | null>,
        default: () => null,
      },

      showContextMenu: {
        type: Boolean,
        default: () => false,
      },
    },

    computed: {
      isCardAppearance() {
        return this.appearance === CardAppearances.card;
      },
    },
  });
