
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "VueToastBody",

    props: {
      title: {
        type: String,
        required: true,
      },

      titleClasses: {
        type: String,
        required: false,
        default: () => "fs-2 fw-bold text-white lh-sm",
      },

      subtitle: {
        type: String,
        required: false,
      },

      subtitleClasses: {
        type: String,
        required: false,
        default: () => "fs-5 text-white lh-1",
      },
    },
  });
