
  import { defineComponent } from "vue";

  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";

  export default defineComponent({
    name: "VueDatePicker",

    components: {
      "date-picker": VueDatePicker,
    },

    props: {
      cancelText: {
        type: String,
        default: () => "Cancelar",
      },

      enableTimePicker: {
        type: Boolean,
        default: () => false,
      },

      nowButtonLabel: {
        type: String,
        default: () => "Hoy",
      },

      placeholder: {
        type: String,
        default: () => "DD / MM / AAAA",
      },

      selectText: {
        type: String,
        default: () => "Seleccionar",
      },

      startDate: {
        default: () => new Date(),
      },
    },

    data() {
      return {
        locale: "es",
      };
    },

    computed: {
      value: {
        get() {
          return this.$attrs.modelValue;
        },

        set(date: any) {
          this.$emit("update:modelValue", date);
        },
      },
    },
  });
