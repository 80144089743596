import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28cdad88"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.attributes, { class: "col text-center p-2 rounded-3 message-box" }), [
    _createElementVNode("span", {
      class: "fs-5 fw-bolder text-gray-700",
      innerHTML: _ctx.content
    }, null, 8, _hoisted_1)
  ], 16))
}