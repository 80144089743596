import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!

  return (_ctx.renderComponents)
    ? (_openBlock(), _createBlock(_component_process_components_iterator, {
        key: 0,
        "view-mode": _ctx.componentViewMode,
        components: _ctx.components,
        "parent-component-key": _ctx.attributes.name || _ctx.parent,
        indexPosition: _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, null, 8, ["view-mode", "components", "parent-component-key", "indexPosition", "modelValue"]))
    : _createCommentVNode("", true)
}