import * as validators from "@vuelidate/validators";

import { createI18nMessage } from "@vuelidate/validators";

import messages from "@/assets/lang/es/validations.json";

async function i18nImport() {
  if (process.env.NODE_ENV === "development") {
    //@ts-ignore
    return await import("vue-i18n/dist/vue-i18n.esm-bundler.js");
  } else {
    //@ts-ignore
    return await import("vue-i18n/dist/vue-i18n.runtime.esm-bundler.js");
  }
}

const i18nLib = await i18nImport();
const i18n = i18nLib.createI18n({ locale: "es", messages });

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const email = withI18nMessage(validators.email, { withArguments: true });

export const integer = withI18nMessage(validators.integer, { withArguments: true });

export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });

export const minLength = withI18nMessage(validators.minLength, { withArguments: true });

export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });

export const minValue = withI18nMessage(validators.minValue, { withArguments: true });

export const numeric = withI18nMessage(validators.numeric);

export const required = withI18nMessage(validators.required);
