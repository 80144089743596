
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "FormTitle",

    extends: BaseProcessComponent,

    data() {
      return {
        title: (this.attributes.value || "") as string,
      };
    },

    created() {
      if (this.attributes.hasOwnProperty("source") && this.applicationStore.application) {
        this.title = getValueByPath(
          { application: this.applicationStore.application },
          this.attributes.source.replace("$", "")
        );
      }
    },
  });
