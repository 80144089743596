export default {
  name: {
    label: "Usuario",
    id: "name",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  email: {
    label: "Correo",
    id: "email",
    colClasses: "col d-none d-sm-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  lastLogin: {
    label: "Último inicio de sesión",
    id: "lastLogin",
    colClasses: "col d-none d-md-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  actions: {
    label: "Acciones",
    id: "actions",
    colClasses: "col-1",
    headerClasses: "text-center fs-5 text-gray-700 fw-bolder",
    cellClasses: "d-flex justify-content-center align-items-center",
  },
};
