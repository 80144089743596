
  import { defineComponent } from "vue";

  import { email } from "@/helpers/i18n-validators";

  import SimpleInput from "@/components/processComponents/SimpleInput.vue";

  export default defineComponent({
    name: "SimpleEmail",

    extends: SimpleInput,

    validations() {
      return {
        value: {
          ...this.rules(),
          email,
        },
      };
    },
  });
