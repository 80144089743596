
  import { defineComponent } from "vue";

  import VueStatusBadge from "@/components/VueStatusBadge.vue";

  export default defineComponent({
    name: "CardInfoRow",

    components: { VueStatusBadge },

    props: {
      source: {
        type: [String, Array],
        required: true,
      },

      title: {
        type: String,
        required: true,
      },

      type: {
        type: String,
        required: false,
      },

      separator: {
        type: Boolean,
        required: false,
        default: () => false,
      },

      name: {
        type: String,
      },
    },
  });
