
  import { defineComponent } from "vue";

  import { isObject, keys, omit } from "lodash";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { v4 as uuidv4 } from "uuid";

  export default defineComponent({
    name: "SwitchConditionalRender",

    extends: BaseProcessComponent,

    data() {
      return {
        componentsToRender: [] as any[],
        formData: {} as any,
        formTemplates: null as any,
        hasSwitchComponent: false as boolean,
        isSelected: false as boolean,
        templates: [] as any[],
        selectedTemplate: null as any,
        switchComponent: null as any,
      };
    },

    computed: {
      containerClasses(): string {
        return this.attributes?.settings?.template_container?.class || "";
      },

      value: {
        get(): any {
          const newValue: any = this.$attrs.modelValue || this.attributes.value || {};
          this.isSelected = this.getSelectedValue(newValue);
          this.selectedTemplate = this.findSelectedTemplate(this.isSelected);

          if (this.selectedTemplate && !newValue.hasOwnProperty(this.selectedTemplate.attributes.name)) {
            newValue[this.selectedTemplate.attributes.name] = {};
          }

          if (
            this.hasSwitchComponent &&
            newValue.hasOwnProperty(this.selectedTemplate?.attributes.name) &&
            keys(newValue).length > 1 &&
            keys(newValue[this.selectedTemplate?.attributes.name]).length
          ) {
            if (
              !this.formData?.hasOwnProperty(this.selectedTemplate?.attributes.name) ||
              !keys(this.formData[this.selectedTemplate.attributes.name]).length ||
              JSON.stringify(newValue) !== JSON.stringify(this.formData || {})
            ) {
              this.$nextTick(() => {
                this.formData = newValue;
              });
            }
          }

          return newValue;
        },

        set(value: any) {
          this.isSelected = this.getSelectedValue(value);

          if (this.switchMapper) {
            const templateOnTrue: any = this.findSelectedTemplate(true);
            const templateOnFalse: any = this.findSelectedTemplate(false);

            if (this.isSelected && value?.hasOwnProperty(templateOnFalse?.attributes.name)) {
              this.$emit("update:modelValue", omit(value, templateOnFalse?.attributes.name || ""));
              this.formData = null;
            } else if (!this.isSelected && value?.hasOwnProperty(templateOnTrue?.attributes.name)) {
              this.$emit("update:modelValue", omit(value, templateOnTrue?.attributes.name || ""));
              this.formData = null;
            } else {
              this.$emit("update:modelValue", value);
            }
          } else {
            if (!this.isSelected) {
              this.formData = null;
            }
            this.$emit(
              "update:modelValue",
              this.isSelected ? value : omit(value, this.selectedTemplate?.attributes.name || "")
            );
          }
        },
      },

      switchMapper(): any {
        return this.switchComponent?.attributes.value_mapper;
      },
    },

    watch: {
      components(newValue) {
        this.setInitialValues(newValue || []);
      },

      formData(newVal) {
        if (newVal) {
          this.$emit("update:modelValue", { ...this.value, ...newVal });
        }
      },

      selectedTemplate(newVal) {
        if (newVal) {
          this.formTemplates = {
            attributes: {},
            components: [newVal],
            events: {},
            viewMode: this.componentViewMode,
            type: "form-templates",
            id: uuidv4(),
          };
        } else {
          this.formTemplates = null;
        }
      },
    },

    created() {
      this.setInitialValues(this.components);
    },

    methods: {
      findSelectedTemplate(switchValue: boolean): any {
        let selectedTemplate: any = null;
        if (this.switchMapper) {
          selectedTemplate = this.templates.find(
            (template: any) => template.attributes.for === this.switchMapper[switchValue ? "onTrue" : "onFalse"]
          );
        } else {
          selectedTemplate = switchValue && this.templates.length ? this.templates[0] : null;
        }

        return selectedTemplate;
      },

      getSelectedValue(values: any): boolean {
        this.hasSwitchComponent = false;
        let switchValue: any = this.searchSwitchValue(values);

        if (this.switchMapper) {
          if (this.switchMapper["onFalse"] === switchValue) {
            switchValue = false;
          } else {
            switchValue = this.switchMapper["onTrue"] === switchValue;
          }
        }

        return switchValue;
      },

      searchSwitchValue(values: any): any {
        let currentValue: any = null;

        for (const key of keys(values)) {
          if (key === this.switchComponent?.attributes.name) {
            this.hasSwitchComponent = true;
            currentValue = values[key];
            break;
          }

          currentValue = this.searchSwitchValue(isObject(values[key]) ? values[key] : {});
          if (currentValue) {
            break;
          }
        }

        return currentValue;
      },

      searchSwitchComponent(components: any[]): any {
        let selectedComponent: any = null;

        for (const component of components) {
          if (component.type === "simple-switch" && component.attributes.hasTemplate) {
            selectedComponent = component;
            break;
          }

          selectedComponent = this.searchSwitchComponent(component.components);
          if (selectedComponent) {
            break;
          }
        }

        return selectedComponent;
      },

      setInitialValues(components: any[]) {
        this.componentsToRender = components.filter((component: any) => component.type !== "form-templates");
        this.templates = [];

        for (const component of components) {
          if (component.type === "form-templates") {
            this.templates.push(...component.components);
          }
        }

        this.switchComponent = this.searchSwitchComponent(this.componentsToRender);
        this.selectedTemplate = this.findSelectedTemplate(this.isSelected);
      },
    },
  });
