
  import { defineComponent } from "vue";
  import { mapStores } from "pinia";

  import { useVuelidate } from "@vuelidate/core";

  import { useApplicationStore } from "@/stores/ApplicationStore";

  enum processComponentsModes {
    visualization = "visualization",
    modification = "modification",
  }

  export default defineComponent({
    name: "BaseProcessComponent",

    props: {
      attributes: {
        type: Object,
        required: true,
      },

      components: {
        type: Array,
        default: () => [],
      },

      events: {
        type: Object,
        default: () => {},
      },

      executeEvent: {
        required: false,
        type: String,
        default: () => "",
      },

      id: {
        type: String,
        required: true,
      },

      indexPosition: {
        type: Number,
        default: () => -1,
      },

      type: {
        type: String,
        required: true,
      },

      viewMode: {
        type: String,
        default: () => processComponentsModes.visualization,
      },

      parent: {
        type: String,
        default: () => "",
      },
    },

    data() {
      return {
        v$: useVuelidate({ $externalResults: {} }) as any,
        viewModes: processComponentsModes as any,
        componentViewMode: this.viewMode || ("" as string),
        serverErrors: [] as string[],
      };
    },

    computed: {
      ...mapStores(useApplicationStore),

      isReadOnly() {
        return this.componentViewMode === this.viewModes.visualization;
      },
    },

    watch: {
      "applicationStore.serverValidationErrors": {
        handler(newValue) {
          if (newValue) {
            if (this.type === "form-container" && !this.attributes.name) {
              this.serverErrors = this.applicationStore.serverValidationErrors?.errors?.data?._schema || [];
            } else {
              this.serverErrors =
                this.applicationStore.getComponentErrorByName(this.attributes.name, this.parent, this.indexPosition) ||
                [];
            }
          }
        },
      },
    },
  });
