import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = {
  key: 1,
  class: "odd"
}
const _hoisted_3 = {
  colspan: "7",
  class: "dataTables_empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeadRow = _resolveComponent("TableHeadRow")!
  const _component_TableBodyRow = _resolveComponent("TableBodyRow")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", {
      class: _normalizeClass([[_ctx.loading && 'overlay overlay-block'], "table table-row-dashed"])
    }, [
      _createVNode(_component_TableHeadRow, {
        onOnSort: _ctx.onSort,
        onOnSelect: _ctx.selectAll,
        checkboxEnabledValue: _ctx.check,
        checkboxEnabled: _ctx.checkboxEnabled,
        "sort-label": _ctx.sortLabel,
        "sort-order": _ctx.sortOrder,
        header: _ctx.header
      }, null, 8, ["onOnSort", "onOnSelect", "checkboxEnabledValue", "checkboxEnabled", "sort-label", "sort-order", "header"]),
      (_ctx.data.length !== 0)
        ? (_openBlock(), _createBlock(_component_TableBodyRow, {
            key: 0,
            onOnSelect: _ctx.itemsSelect,
            currentlySelectedItems: _ctx.selectedItems,
            data: _ctx.data,
            header: _ctx.header,
            "checkbox-enabled": _ctx.checkboxEnabled,
            "checkbox-label": _ctx.checkboxLabel
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.$slots, (_, name) => {
              return {
                name: name,
                fn: _withCtx(({ row: item }) => [
                  _renderSlot(_ctx.$slots, name, { row: item })
                ])
              }
            })
          ]), 1032, ["onOnSelect", "currentlySelectedItems", "data", "header", "checkbox-enabled", "checkbox-label"]))
        : (_openBlock(), _createElementBlock("tr", _hoisted_2, [
            _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.emptyTableText), 1)
          ])),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Loading, { key: 2 }))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}