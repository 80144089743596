
  import { defineComponent } from "vue";

  import SimpleDate from "@/components/processComponents/SimpleDate.vue";

  export default defineComponent({
    name: "SimpleTime",

    extends: SimpleDate,

    computed: {
      formattedHours() {
        return this.value ? `${this.value.hours}:${this.value.minutes} ${this.dateTime}` : "";
      },

      dateTime() {
        return this.value.hours >= 12 ? "PM" : "AM";
      },

      value: {
        get() {
          return this.$attrs.modelValue || this.attributes.value || null;
        },

        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },
    },
  });
