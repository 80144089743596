
  import { defineComponent } from "vue";

  import { numeric, maxValue, minValue } from "@vuelidate/validators";
  import { vMaska } from "maska";

  import SimpleInput from "@/components/processComponents/SimpleInput.vue";

  export default defineComponent({
    name: "IntegerInput",

    directives: { maska: vMaska },

    extends: SimpleInput,

    validations() {
      return {
        value: {
          ...this.rules(),
          minValue: minValue(0),
          ...(this.attributes.min && { minValue: minValue(this.attributes.min) }),
          ...(this.attributes.max && { maxValue: maxValue(this.attributes.max) }),
          numeric,
        },
      };
    },

    computed: {
      value: {
        get() {
          let newValue: any = this.$attrs.modelValue;

          return newValue;
        },

        set(value: any) {
          if (value.trim() === "") {
            this.$emit("update:modelValue", value);
          }

          if (Number(value) || value === "0") {
            this.$emit("update:modelValue", Number(value));
          }
        },
      },
    },
  });
