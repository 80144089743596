import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!

  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.attributes, {
    class: { 'no-click': _ctx.attributes.disabled, 'col-12': _ctx.attributes.class && !_ctx.attributes.class.includes('col-') }
  }), [
    _createVNode(_component_process_components_iterator, {
      components: _ctx.components,
      "index-position": _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
      layout: _ctx.attributes.layout,
      "view-mode": _ctx.componentViewMode,
      "parent-component-key": _ctx.attributes.name || _ctx.parent,
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
    }, null, 8, ["components", "index-position", "layout", "view-mode", "parent-component-key", "modelValue"])
  ], 16))
}