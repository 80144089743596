import qs from "qs";

import BaseApiClient from "./BaseApiClient";

export default class Applications extends BaseApiClient {
  constructor() {
    super("v1/applications");
  }

  create(data: any = {}) {
    return this.axiosInstance({
      url: "/",
      method: "post",
      data: data,
    });
  }

  read(applicationId: string) {
    return this.axiosInstance({
      url: `/${applicationId}`,
      method: "get",
    });
  }

  update(applicationId: string, tags: any[]) {
    return this.axiosInstance({
      url: `/${applicationId}`,
      method: "put",
      data: { tags },
    });
  }

  remove(applicationId: string) {
    return this.axiosInstance({
      url: `/${applicationId}`,
      method: "delete",
    });
  }

  search(queryStrings: any = {}) {
    return this.axiosInstance({
      url: "/",
      method: "get",
      params: queryStrings,
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
  }

  /* ----------------------------------------------- APPLICATION STEPS ------------------------------------------------ */
  async searchSteps(applicationId: string, queryStrings: any = {}) {
    return this.axiosInstance({
      url: `/${applicationId}/steps`,
      method: "get",
      params: queryStrings,
    });
  }

  async saveStepData(applicationId: string, applicationStepId: string, data: any = {}) {
    return this.axiosInstance({
      url: `/${applicationId}/steps/${applicationStepId}/data`,
      method: "post",
      data: data,
    });
  }

  async saveStepDataWithTags(applicationId: string, applicationStepId: string, tags: any[], data: any = {}) {
    return this.axiosInstance({
      url: `/${applicationId}/steps/${applicationStepId}`,
      method: "put",
      data: { data, tags },
    });
  }

  async readStepData(applicationId: string, applicationStepId: string) {
    return this.axiosInstance({
      url: `/${applicationId}/steps/${applicationStepId}/data`,
      method: "get",
    });
  }
}

export function applicationIcons(): any {
  let icons: any = { default: { icon: "fa-car" } };

  icons[process.env.VUE_APP_CAR_INSURANCE] = {
    icon: "fa-car",
  };

  return icons;
}
