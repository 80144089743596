import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm text-center text-sm-start" }
const _hoisted_3 = {
  key: 0,
  class: "fw-normal text-gray-700 mt-2"
}
const _hoisted_4 = {
  key: 1,
  class: "fw-normal text-gray-700 mt-2"
}
const _hoisted_5 = { class: "col-sm pagination-container" }
const _hoisted_6 = { class: "pagination justify-content-sm-end" }
const _hoisted_7 = { class: "page-link cursor-pointer" }
const _hoisted_8 = { class: "page-link cursor-pointer" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "page-link cursor-pointer" }
const _hoisted_11 = { class: "page-link cursor-pointer" }
const _hoisted_12 = { class: "page-link cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.totalPages > 0)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, " Mostrando " + _toDisplayString(_ctx.pageItemStartNumber) + " a " + _toDisplayString(_ctx.pageItemEndNumber) + " de " + _toDisplayString(_ctx.totalItems) + " " + _toDisplayString(_ctx.itemLabel), 1))
        : (_openBlock(), _createElementBlock("h3", _hoisted_4, "No hay " + _toDisplayString(_ctx.itemLabel) + " que mostrar", 1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", _hoisted_6, [
        _createVNode(_component_popper, {
          class: "dark-popper",
          arrow: "",
          hover: "",
          placement: "top",
          content: "Primera página"
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", {
              class: _normalizeClass(["page-item first", { disabled: this.isFirstPage }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goFirstPage && _ctx.goFirstPage(...args)))
            }, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_font_awesome_icon, { icon: "chevron-left" }),
                _createVNode(_component_font_awesome_icon, { icon: "chevron-left" })
              ])
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_popper, {
          class: "dark-popper",
          arrow: "",
          hover: "",
          placement: "top",
          content: "Atrás"
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", {
              class: _normalizeClass(["page-item previous", { disabled: !this.canGoBackward }]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goBackward && _ctx.goBackward(...args)))
            }, [
              _createElementVNode("span", _hoisted_8, [
                _createVNode(_component_font_awesome_icon, { icon: "chevron-left" })
              ])
            ], 2)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partitionRange, (n) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `page-${n}`,
            class: _normalizeClass(["page-item", { active: _ctx.isCurrentPage(n) }]),
            onClick: ($event: any) => (_ctx.goToPage(n))
          }, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(n), 1)
          ], 10, _hoisted_9))
        }), 128)),
        _createVNode(_component_popper, {
          class: "dark-popper",
          arrow: "",
          hover: "",
          placement: "top",
          content: "Siguiente"
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", {
              class: _normalizeClass(["page-item next", { disabled: !this.canGoForward }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goForward && _ctx.goForward(...args)))
            }, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
              ])
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_popper, {
          class: "dark-popper",
          arrow: "",
          hover: "",
          placement: "top",
          content: "Última página"
        }, {
          default: _withCtx(() => [
            _createElementVNode("li", {
              class: _normalizeClass(["page-item first", { disabled: this.isLastPage }]),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goLastPage && _ctx.goLastPage(...args)))
            }, [
              _createElementVNode("span", _hoisted_12, [
                _createVNode(_component_font_awesome_icon, { icon: "chevron-right" }),
                _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
              ])
            ], 2)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}