import { createVNode, VNode } from "vue";

import { useToast, PluginOptions, POSITION } from "vue-toastification";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueToastBody from "@/components/VueToastBody.vue";

const toast = useToast();

const DEFAULT_CLOSE_BUTTON_ICON: string = "fa-xmark";

const DEFAULT_OPTIONS: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  draggable: true,
  closeOnClick: false,
  hideProgressBar: true,
  timeout: false,
};

enum ToastType {
  error,
  default,
  info,
  success,
  warning,
}

interface BaseOptions {
  title: string;
  subtitle?: string;
  leadIcon?: string;
  closeButtonIcon?: string;
  customOptions?: PluginOptions;
}

function iconNode(icon: string): VNode {
  return createVNode(FontAwesomeIcon, { icon });
}

export function baseToast(
  { title, subtitle, leadIcon, closeButtonIcon = DEFAULT_CLOSE_BUTTON_ICON, customOptions }: BaseOptions,
  type: keyof typeof ToastType = "info"
) {
  if (type !== "default") {
    (toast as any)[type](
      {
        component: VueToastBody,
        props: {
          title,
          subtitle,
        },
      },
      {
        ...DEFAULT_OPTIONS,
        icon: leadIcon ? iconNode(leadIcon) : false,
        closeButton: iconNode(closeButtonIcon),
        ...customOptions,
      }
    );
  } else {
    toast(
      {
        component: VueToastBody,
        props: {
          title,
          subtitle,
        },
      },
      {
        ...DEFAULT_OPTIONS,
        icon: leadIcon ? iconNode(leadIcon) : false,
        closeButton: iconNode(closeButtonIcon),
        ...customOptions,
      }
    );
  }
}
