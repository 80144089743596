
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import DriverExecutionError from "@/core/errors/DriverExecutrionError";
  import { getValueByPath } from "@/helpers/utils";
  import { maxLength, minLength, required } from "@/helpers/i18n-validators";

  export default defineComponent({
    name: "CatalogSelect",

    extends: BaseProcessComponent,

    data() {
      return {
        options: [] as any[],
      };
    },

    validations() {
      return {
        value: {
          ...(this.attributes.required && { required }),
          ...(this.attributes.minlength && {
            minLength: minLength(this.attributes.minlength),
          }),
          ...(this.attributes.maxlength && {
            maxLength: maxLength(this.attributes.maxlength),
          }),
          $autoDirty: true,
        },
      };
    },

    computed: {
      containerClasses(): string {
        return `multiselect ${this.attributes.classes?.container ?? ""}`;
      },

      isMultiple(): boolean {
        return this.attributes.multiple;
      },

      searchInputClasses(): string {
        return `multiselect-search ${this.attributes.classes?.search ?? ""}`;
      },

      value: {
        get() {
          let modelValue: any = this.$attrs.modelValue || this.attributes.value || null;

          if (Array.isArray(modelValue)) {
            if (modelValue.length && modelValue[0].hasOwnProperty("detalle")) {
              modelValue = modelValue.map((item: any) => ({ label: item.detalle, value: item.codigo }));
            }
          } else if (modelValue) {
            if (modelValue.hasOwnProperty("detalle")) {
              modelValue = { label: modelValue.detalle, value: modelValue.codigo };
            }
          } else if (this.isMultiple) {
            modelValue = [];
          }

          return modelValue;
        },

        set(newVal: any) {
          let value: any = newVal;
          if (Array.isArray(newVal)) {
            value = newVal.map((item: any) => ({ codigo: item.value, detalle: item.label }));
          } else if (newVal?.hasOwnProperty("value")) {
            value = { codigo: newVal.value, detalle: newVal.label };
          }

          this.$emit("update:modelValue", value);
        },
      },

      valueIsArray(): any {
        return Array.isArray(this.value);
      },
    },

    async created() {
      if (this.attributes.hasOwnProperty("source") && this.applicationStore.application && !this.value) {
        await this.$nextTick(() => {
          this.value = getValueByPath(
            { application: this.applicationStore.application },
            this.attributes.source.replace("$", "")
          );
        });
      }

      if (!this.isReadOnly && this.attributes.settings) {
        await this.loadOptions();
      }
    },

    methods: {
      async loadOptions() {
        try {
          const driversClient = new Drivers();
          this.options.push({ label: "Cargando elementos...", value: null, disabled: true });
          const response = await driversClient.execute(
            process.env.VUE_APP_GET_CATALOGS_DRIVER,
            this.attributes.settings
          );

          if (response) {
            const items = response.data.catalogs ? response.data.catalogs[0].items : [];
            this.options = items.map((item: any) => ({ label: item.detalle, value: item.codigo }));

            if (this.valueIsArray && this.value.length !== 0) {
              this.value = this.value.filter((selectedValue: any) =>
                this.options.some((option: any) => selectedValue.value === option.value)
              );
            } else if (
              !this.valueIsArray &&
              this.value &&
              !this.options.some((option: any) => this.value.value === option.value)
            ) {
              this.value = null;
            }
          }
        } catch (error) {
          this.options = [{ label: "Error al cargar el catálogo ", value: null, disabled: true }];
          this.value = null;
          throw new DriverExecutionError(error);
        }
      },
    },
  });
