import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28e6dd7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-menu" }
const _hoisted_2 = { class: "d-flex flex-column justify-content-center rounded-3 ellipsis-vertical" }
const _hoisted_3 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_popper = _resolveComponent("popper")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_popper, { placement: "bottom-end" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "options")
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle'] }),
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle'] }),
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle'] })
        ])
      ]),
      _: 3
    })
  ], 512)), [
    [_vShow, _ctx.show]
  ])
}