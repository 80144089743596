<template>
  <router-view />
</template>

<script>
  import { defineComponent } from "vue";
  import { mapStores } from "pinia";

  import Swal from "sweetalert2";

  import Drivers from "@/helpers/clients/Drivers";
  import { useUserStore } from "@/stores/UserStore";
  import { swalWarning } from "@/helpers/sweetalert";

  export default defineComponent({
    computed: {
      ...mapStores(useUserStore),
    },

    created() {
      document.addEventListener("visibilitychange", this.validateSession);
      window.addEventListener("focus", this.validateSession);
      document.documentElement.setAttribute("data-bs-theme", "light");
    },

    methods: {
      validateSession() {
        if (document.visibilityState === "visible" && this.$route.name !== "Login") {
          const modalInstance = Swal.getContainer();
          if (modalInstance?.id === "session-expired-notification" && this.userStore.isTokenValid()) {
            Swal.close();
          } else {
            const driversClient = new Drivers();

            driversClient.execute(process.env.VUE_APP_CHECK_TOKEN_DRIVER_ID, {}).catch(() => {
              this.userStore.clear();
              swalWarning({
                confirmButtonText: "Iniciar sesión",
                title: "Su sesión ha expirado",
                text: "Por favor inicie sesión de nuevo",
                allowEscapeKey: false,
              }).then((response) => {
                if (response.isConfirmed) {
                  this.$router.push({
                    name: "Login",
                    query: { redirect: this.$route.path, redirect_cause: "unauthenticated" },
                  });
                }
              });
              Swal.getContainer().id = "session-expired-notification";
            });
          }
        }
      },
    },
  });
</script>
