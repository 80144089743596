
  import { defineComponent } from "vue";

  import { isEqual } from "lodash";
  import { maxLength, maxValue, minLength, minValue, numeric, required } from "@/helpers/i18n-validators";
  import { vMaska } from "maska";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import DriverExecutionError from "@/core/errors/DriverExecutrionError";
  import { helpers } from "@vuelidate/validators";

  export default defineComponent({
    name: "CurrencyInput",

    directives: { maska: vMaska },

    extends: BaseProcessComponent,

    props: {
      modelValue: {
        type: Object as any,
      },
    },

    validations() {
      return {
        componentValues: {
          currency: {
            required: helpers.withMessage(this.currencyRequiredMessage, required),
            ...(this.currencyAttributes.minlength && {
              minLength: minLength(this.currencyAttributes.minlength),
            }),
            ...(this.currencyAttributes.maxlength && {
              maxLength: maxLength(this.currencyAttributes.maxlength),
            }),
            $autoDirty: true,
          },
          value: {
            minValue: minValue(0),
            required: helpers.withMessage(this.amountRequiredMessage, required),
            ...(this.amountAttributes.min && { minValue: minValue(this.amountAttributes.min) }),
            ...(this.amountAttributes.max && { maxValue: maxValue(this.amountAttributes.max) }),
            numeric,
            $autoDirty: true,
          },
        },
      };
    },

    data() {
      return {
        amountValue: null as any,
        bindedObject: {
          masked: "",
          unmasked: "",
          completed: false,
        } as any,
        componentValues: {
          currency: null,
          value: null,
        },
        currencyOptions: {
          preProcess: (val: any) => val.replace(/[$,]/g, ""),
          postProcess: (val: any) => {
            if (!val) return "";

            const sub = 3 - (val.includes(".") ? val.length - val.indexOf(".") : 0);

            let formattedNumber: any = Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            })
              .format(val)
              .slice(0, sub ? -sub : undefined)
              .replace(/[$]/g, "");

            return `${this.currencySymbol} ${formattedNumber}`.trim();
          },
        } as any,
        currencySymbol: "" as string,
        currencyValue: null as any,
        firstLoad: false as boolean,
        myServerErrors: [] as string[],
        options: [] as any[],
      };
    },

    computed: {
      amountAttributes(): any {
        return this.attributes.settings?.amount?.attributes || {};
      },

      amountRequiredMessage(): string {
        return this.amountSettings.errorMessages?.required || `El campo ${this.amountAttributes.name} es requerido.`;
      },

      amountSettings(): any {
        return this.attributes.settings?.amount?.settings || {};
      },

      currencyAttributes(): any {
        return this.attributes.settings?.currency?.attributes || {};
      },

      currencyRequiredMessage(): string {
        return (
          this.currencySettings.errorMessages?.required || `El campo ${this.currencyAttributes.name} es requerido.`
        );
      },

      currencySettings(): any {
        return this.attributes.settings?.currency?.settings || {};
      },

      maskedValue(): string {
        if (this.amountAttributes.name && this.modelValue?.hasOwnProperty(this.amountAttributes.name)) {
          const value: any = this.modelValue[this.amountAttributes.name];
          let formattedNumber: any = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          })
            .format(value)
            .replace(/[$]/g, "");

          return `${this.currencySymbol} ${formattedNumber}`.trim();
        }

        return "";
      },
    },

    watch: {
      "applicationStore.serverValidationErrors": {
        handler(newValue) {
          if (newValue) {
            let currencyErrors: any[] = [];
            if (this.currencyAttributes.name) {
              currencyErrors =
                this.applicationStore.getComponentErrorByName(
                  this.currencyAttributes.name,
                  this.attributes.name || this.parent,
                  this.indexPosition
                ) || [];
            }

            let valueErrors: any[] = [];
            if (this.amountAttributes.name) {
              valueErrors =
                this.applicationStore.getComponentErrorByName(
                  this.amountAttributes.name,
                  this.attributes.name || this.parent,
                  this.indexPosition
                ) || [];
            }

            let componentErrors: any[] = [];
            if (this.attributes.name) {
              componentErrors =
                this.applicationStore.getComponentErrorByName(this.attributes.name, this.parent, this.indexPosition) ||
                [];
            }

            this.myServerErrors = componentErrors.concat(currencyErrors, valueErrors);
          }
        },
      },

      "bindedObject.masked"(newVal) {
        let modelValue: any = { ...(this.modelValue || {}) };
        let currency: any = "";

        if (newVal !== "" && Number(newVal) >= 0) {
          currency = Number(newVal);
        }

        if (this.amountAttributes.name) {
          modelValue[this.amountAttributes.name] = currency;
        }

        this.componentValues.value = currency;
        this.$emit("update:modelValue", modelValue);
      },

      modelValue(newVal, oldValue) {
        this.$nextTick(() => {
          if (!isEqual(newVal, oldValue)) {
            this.setValues(newVal);
          }
        });
      },

      currencySymbol(_) {
        if (this.modelValue?.hasOwnProperty(this.amountAttributes.name)) {
          const newValue: any = this.modelValue[this.amountAttributes.name].toLocaleString("fullwide", {
            useGrouping: false,
          });

          this.amountValue = this.currencyOptions.postProcess(newValue);
          this.componentValues.value = this.modelValue[this.amountAttributes.name];
        }
      },

      currencyValue(newVal) {
        let modelValue: any = { ...(this.modelValue || {}) };
        if (this.currencyAttributes.name && newVal?.hasOwnProperty("value")) {
          modelValue[this.currencyAttributes.name] = { codigo: newVal.value, detalle: newVal.label };
        }

        if (!newVal) {
          this.currencySymbol = "";
          modelValue[this.currencyAttributes.name] = null;
        }

        this.componentValues.currency = newVal;
        this.$emit("update:modelValue", modelValue);
      },

      firstLoad(newVal) {
        if (newVal) {
          this.setValues(this.modelValue);
          this.firstLoad = false;
        }
      },
    },

    async created() {
      if (this.currencySettings.hasOwnProperty("driverSettings")) {
        try {
          const driversClient = new Drivers();

          this.options.push({ label: "Cargando elementos...", value: null, disabled: true });
          const response = await driversClient.execute(
            process.env.VUE_APP_GET_CATALOGS_DRIVER,
            this.currencySettings.driverSettings
          );

          if (response) {
            const items = response.data.catalogs ? response.data.catalogs[0].items : [];
            this.options = items.map((item: any) => {
              let option: any = { label: item.detalle, value: item.codigo };

              if (
                item.catalogs?.length !== 0 &&
                item.catalogs[0].code === "SLS0021" &&
                item.catalogs[0].items?.length !== 0
              ) {
                option.currencySymbol = item.catalogs[0].items[0].detalle;
              }

              return option;
            });

            if (
              this.modelValue?.hasOwnProperty(this.currencyAttributes.name) &&
              !this.options.some((option: any) => this.modelValue[this.currencyAttributes.name]?.value === option.value)
            ) {
              this.currencyValue = null;
            }

            this.firstLoad = true;
          }
        } catch (error) {
          this.options = [{ label: "Error al cargar el catálogo ", value: null, disabled: true }];
          this.currencyValue = null;
          throw new DriverExecutionError(error);
        }
      }
    },

    methods: {
      setValues(newVal: any) {
        if (
          newVal?.hasOwnProperty(this.amountAttributes.name) &&
          (newVal[this.amountAttributes.name] || newVal[this.amountAttributes.name] === 0) &&
          this.amountValue === null
        ) {
          this.amountValue = newVal[this.amountAttributes.name];
          this.componentValues.value = this.amountValue;
        }

        if (newVal?.hasOwnProperty(this.currencyAttributes.name) && newVal[this.currencyAttributes.name]?.codigo) {
          this.currencyValue = {
            label: newVal[this.currencyAttributes.name].detalle,
            value: newVal[this.currencyAttributes.name].codigo,
          };

          this.componentValues.currency = this.currencyValue;
          this.currencySymbol =
            this.options.find((option: any) => option.value === this.currencyValue.value)?.currencySymbol || "";
        }
      },
    },
  });
