import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "fw-semibold text-gray-600" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, i) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: i,
        class: "fw-bold fs-5 text-gray-600 text-start"
      }, [
        (_ctx.checkboxEnabled)
          ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  value: row[_ctx.checkboxLabel],
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event)),
                  onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
                }, null, 40, _hoisted_4), [
                  [_vModelCheckbox, _ctx.selectedItems]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (properties, j) => {
          return (_openBlock(), _createElementBlock("td", {
            key: j,
            class: "px-3"
          }, [
            _renderSlot(_ctx.$slots, `${properties.columnLabel}`, { row: row }, () => [
              _createTextVNode(_toDisplayString(row), 1)
            ])
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}