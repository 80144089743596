
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";

  export default defineComponent({
    name: "FormContainer",

    extends: BaseProcessComponent,

    computed: {
      value: {
        get() {
          return this.$attrs.modelValue || {};
        },

        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },

      title(): string {
        return this.attributes.title || "";
      },

      subtitle(): string {
        return this.attributes.subtitle || "";
      },
    },
  });
