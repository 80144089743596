import { defineStore } from "pinia";

export const useMasterLayoutStore = defineStore("masterLayout", {
  state: () => ({
    mobile: window.innerWidth < 1199,
    open: false,
    spinnerOn: false,
  }),

  actions: {
    toggleOpen() {
      this.open = !this.open;
    },

    toggleSpinner() {
      this.spinnerOn = !this.spinnerOn;
    },
  },
});
