
  import { computed, defineComponent, ref, watch } from "vue";
  import TableContent from "@/components/kt-datatable/table-partials/table-content/TableContent.vue";
  import TableFooter from "@/components/kt-datatable/table-partials/TableFooter.vue";
  import type { Sort } from "@/components/kt-datatable/table-partials/models";

  export default defineComponent({
    name: "kt-datatable",
    props: {
      header: { type: Array, required: true },
      data: { type: Array, required: true },
      itemsPerPage: { type: Number, default: 10 },
      itemsPerPageDropdownEnabled: {
        type: Boolean,
        required: false,
        default: true,
      },
      checkboxEnabled: { type: Boolean, required: false, default: false },
      checkboxLabel: { type: String, required: false, default: "id" },
      total: { type: Number, required: false },
      loading: { type: Boolean, required: false, default: false },
      sortLabel: { type: String, required: false, default: null },
      sortOrder: {
        type: String as () => "asc" | "desc",
        required: false,
        default: "asc",
      },
      emptyTableText: { type: String, required: false, default: "No data found" },
      currentPage: { type: Number, required: false, default: 1 },
    },
    emits: ["page-change", "on-sort", "on-items-select", "on-items-per-page-change"],
    components: {
      TableContent,
      TableFooter,
    },
    setup(props, { emit }) {
      const currentPage = ref(props.currentPage);
      const itemsInTable = ref<number>(props.itemsPerPage);

      watch(
        () => itemsInTable.value,
        (val) => {
          currentPage.value = 1;
          emit("on-items-per-page-change", val);
        }
      );

      const pageChange = (page: number) => {
        currentPage.value = page;
        emit("page-change", page);
      };

      const dataToDisplay = computed(() => {
        if (props.data) {
          if (props.data.length <= itemsInTable.value) {
            return props.data;
          } else {
            let sliceFrom = (currentPage.value - 1) * itemsInTable.value;
            return props.data.slice(sliceFrom, sliceFrom + itemsInTable.value);
          }
        }
        return [];
      });

      const totalItems = computed(() => {
        if (props.data) {
          if (props.data.length <= itemsInTable.value) {
            return props.total;
          } else {
            return props.data.length;
          }
        }
        return 0;
      });

      const onSort = (sort: Sort) => {
        emit("on-sort", sort);
      };

      //eslint-disable-next-line
      const onItemSelect = (selectedItems: any) => {
        emit("on-items-select", selectedItems);
      };

      return {
        pageChange,
        dataToDisplay,
        onSort,
        onItemSelect,
        itemsInTable,
        totalItems,
      };
    },
  });
