import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_relational_inner_select = _resolveComponent("relational-inner-select")!

  return (_openBlock(), _createBlock(_component_relational_inner_select, {
    id: _ctx.id,
    modelValue: _ctx.selectedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
    type: "relational-inner-select",
    attributes: _ctx.overriddenAttributes,
    class: _normalizeClass({ 'no-click': _ctx.attributes.disabled }),
    events: _ctx.events,
    "view-mode": _ctx.componentViewMode
  }, null, 8, ["id", "modelValue", "attributes", "class", "events", "view-mode"]))
}