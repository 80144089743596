
  import { defineComponent } from "vue";

  import { maxLength, minLength, required } from "@/helpers/i18n-validators";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import DriverExecutionError from "@/core/errors/DriverExecutrionError";
  import { getDataFromSchema, getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "SimpleSelect",

    extends: BaseProcessComponent,

    data() {
      return {
        options: (this.attributes.options || null) as any[] | null,
      };
    },

    validations() {
      return {
        selectedOptions: {
          ...(this.attributes.required && { required }),
          ...(this.attributes.minlength && {
            minLength: minLength(this.attributes.minlength),
          }),
          ...(this.attributes.maxlength && {
            maxLength: maxLength(this.attributes.maxlength),
          }),
          $autoDirty: true,
        },
      };
    },

    computed: {
      containerClasses(): string {
        return `multiselect ${this.attributes.classes?.container ?? ""}`;
      },

      isMultiple(): boolean {
        return this.attributes.multiple;
      },

      searchInputClasses(): string {
        return `multiselect-search ${this.attributes.classes?.search ?? ""}`;
      },

      selectedOptions: {
        get(): any {
          return this.$attrs.modelValue || this.attributes.value || null;
        },

        set(newVal: any) {
          this.$emit("update:modelValue", newVal?.hasOwnProperty("value") ? newVal.value : newVal);
        },
      },

      valueIsArray(): any {
        return Array.isArray(this.selectedOptions);
      },
    },

    watch: {
      attributes: {
        handler(newVal) {
          if (newVal.hasOwnProperty("options")) {
            this.options = newVal.options;
          }
        },
        deep: true,
      },
    },

    async created() {
      const driversClient = new Drivers();

      if (!this.isReadOnly && this.events?.hasOwnProperty("load")) {
        let action: any = {};
        for (action of this.events.load) {
          try {
            const response = await driversClient.execute(
              action.driver.id,
              getDataFromSchema(action.driver.data_schema, {})
            );
            this.options = response.data.catalogs ? response.data.catalogs[0].items : [];
          } catch (error) {
            throw new DriverExecutionError(error);
          }
        }
      } else if (this.attributes.hasOwnProperty("source")) {
        if (this.applicationStore.application) {
          this.options =
            getValueByPath(
              { application: this.applicationStore.application },
              this.attributes.source.replace("$", "")
            ) || [];
        }
      } else if (this.attributes.hasOwnProperty("sources") && Array.isArray(this.attributes.sources)) {
        this.options = this.resolveAndConcatenateValues(this.attributes.sources);
      }

      if (this.options?.length) {
        this.options = [...new Set(this.options)];
      }
    },

    methods: {
      resolveAndConcatenateValues(sources: string[]): string[] {
        let results: string[] = [];

        for (const source of sources) {
          if (Array.isArray(source)) {
            let resolvedValue = "";
            for (const innerSource of source) {
              resolvedValue = resolvedValue
                .concat(
                  " ",
                  getValueByPath({ application: this.applicationStore.application }, innerSource.replace("$", ""))
                )
                .trim();
            }
            results.push(resolvedValue);
          } else {
            const resolvedValue = getValueByPath(
              { application: this.applicationStore.application },
              source.replace("$", "")
            );
            if (resolvedValue) {
              results.push(resolvedValue);
            }
          }
        }

        return results;
      },
    },
  });
