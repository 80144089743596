import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import { keys } from "lodash";

import Applications from "@/helpers/clients/Applications";

export const useApplicationStore = defineStore("application", {
  state: () => {
    return {
      application: null as any,
      applicationSteps: null as any,
      cachedQuotes: useStorage("cachedApplications", []) as any,
      open: false,
      serverValidationErrors: null as any,
    };
  },

  actions: {
    cleanCachedQuotes() {
      this.cachedQuotes = [];
    },

    getCachedQuotes() {
      return this.cachedQuotes.map((applicationId: string) => {
        return { id: applicationId };
      });
    },

    getComponentErrorByName(name: string = "", parent: string, index: number) {
      if (!this.serverValidationErrors.errors?.data || !name) {
        return null;
      }

      return this.findComponentErrors(this.serverValidationErrors.errors?.data, name, parent, index);
    },

    filterApplicationStepsByStatus(step_statuses: string[]) {
      return this.applicationSteps.filter((step: any) => {
        return step.grouped_tags?.quote_step_status?.some((status: any) => {
          return step_statuses.includes(status.id);
        });
      });
    },

    findComponentErrors(errors: any, name: string, parent: string, index: number): any {
      if (errors[name]) {
        return errors[name]["_schema"] ? errors[name]["_schema"] : errors[name];
      } else if (parent && typeof errors !== "string") {
        if (errors[parent]) {
          return this.findErrorsByParent(errors, name, parent, index);
        } else {
          return this.findErrorsInSubLevel(errors, name, parent, index);
        }
      }

      return null;
    },

    findErrorsByParent(errors: any, name: string, parent: string, index: number): any {
      let foundErrors: any = null;
      if (index >= 0) {
        if (errors[parent][index]) {
          if (errors[parent][index]["_schema"] && !name) {
            foundErrors = errors[parent][index]["_schema"];
          } else {
            foundErrors = this.findComponentErrors(errors[parent][index], name, parent, index);
          }
        }
      } else {
        foundErrors = this.findComponentErrors(errors[parent], name, parent, index);
      }

      return foundErrors;
    },

    findErrorsInSubLevel(errors: any, name: string, parent: string, index: number): any {
      let foundErrors: any = null;

      for (const componentName of keys(errors)) {
        if (!Array.isArray(errors[componentName])) {
          if (
            errors[componentName][index] && // NOSONAR
            errors[componentName][index][parent] &&
            errors[componentName][index][parent][name]
          ) {
            foundErrors = this.findComponentErrors(errors[componentName][index][parent], name, parent, index);
          } else {
            foundErrors = this.findComponentErrors(errors[componentName], name, parent, index);
          }

          if (foundErrors) {
            break;
          }
        }
      }

      return foundErrors;
    },

    async loadApplication(applicationId: string) {
      const applicationsClient: Applications = new Applications();
      this.application = null;
      const response: any = await applicationsClient.read(applicationId);
      this.application = response.data;
    },

    async loadApplicationSteps(visualization: string[], statuses: string[] = []) {
      const applicationsClient: Applications = new Applications();
      this.applicationSteps = null;
      const response: any = await applicationsClient.searchSteps(this.application.id, {
        step_visibility_id: visualization,
        quote_step_status_id: statuses,
        page_size: "all",
      });
      this.applicationSteps = response.data.results;
    },

    replaceApplicationStep(step: any) {
      if (this.applicationSteps) {
        const index = this.applicationSteps.findIndex((application_step: any) => application_step.id === step.id);
        if (index >= 0) {
          this.applicationSteps[index] = step;
        }
      }
    },

    toggleOpen() {
      this.open = !this.open;
    },

    setCachedQuotes(applicationId: string) {
      this.cachedQuotes.push(applicationId);
    },
  },
});
