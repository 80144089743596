
  import { defineComponent } from "vue";

  import axios from "axios";
  import { helpers } from "@vuelidate/validators";
  import { mapStores } from "pinia";
  import { merge } from "lodash";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import DriverExecutionError from "@/core/errors/DriverExecutrionError";
  import { baseToast } from "@/helpers/toastification";
  import { getDataFromSchema } from "@/helpers/utils";
  import { useApplicationStore } from "@/stores/ApplicationStore";
  import { useMasterLayoutStore } from "@/stores/MasterLayoutStore";

  /**
   * Custom Types
   * ---------------------------------------------------
   */
  interface FileUploadComponentValueType {
    name: string;
    content_type: string;
    comments?: string;
    document_id?: string;
  }

  /**
   * Custom Validations
   * ---------------------------------------------------
   */
  // TODO: Agregar a archivo de custom_validators y agregarlos a i18n-validators para centralizar las validaciones
  const acceptedFileTypes = (field: FileUploadComponentValueType, allowedTypes: string[]) =>
    helpers.withParams({ field, allowedTypes }, (value: string) => {
      if (!field || field.name === "") {
        return true;
      }

      if (allowedTypes.includes("images/*") && field.content_type.startsWith("image/")) {
        return true;
      }

      return !helpers.req(value) || allowedTypes.includes(field.content_type);
    });

  const maxFileSize = (file: File | null, maxSizeInBytes: number) =>
    helpers.withParams({ file, maxSizeInBytes }, (value: string) => {
      if (!file) {
        return true;
      }
      return !helpers.req(value) || file.size <= maxSizeInBytes;
    });

  const requiredFile = (currentFile: FileUploadComponentValueType | null) =>
    helpers.withParams({ currentFile }, (value: string) => {
      if (currentFile?.content_type) {
        return true;
      }

      return !helpers.req(value);
    });

  /**
   * Component Definition
   * ---------------------------------------------------
   */
  export default defineComponent({
    name: "FileUpload",

    extends: BaseProcessComponent,

    emits: ["invalidSettings", "update:modelValue"],

    data() {
      return {
        current_file: {
          name: "",
          content_type: "",
        } as FileUploadComponentValueType,
        error: null as string | null,
        file: null as File | null,
        firstLoad: false,
        isWaiting: false as boolean,
        visualizationUrl: null as string | null,
      };
    },

    validations() {
      return {
        current_file: {
          ...{
            acceptedFileTypes: helpers.withMessage(
              "El tipo de archivo seleccionado es inválido.",
              acceptedFileTypes(this.current_file, ["application/pdf", "images/*"])
            ),
            ...(this.attributes?.restrictions?.maxFileSize && {
              maxFileSize: helpers.withMessage(
                "El archivo excede el máximo permitido.",
                maxFileSize(this.file, this.attributes.restrictions.maxFileSize)
              ),
            }),
            requiredFile: helpers.withMessage("Este campo es requerido.", requiredFile(this.current_file)),
          },
          $autoDirty: true,
        },
      };
    },

    computed: {
      ...mapStores(useApplicationStore, useMasterLayoutStore),

      fileName: {
        get(): string {
          if (!this.file && !this.current_file.name) {
            this.current_file.name = "";
          } else if (!this.current_file.name) {
            this.current_file.name = this.attributes.settings?.name ?? this.attributes.name;
          }

          return this.current_file.name;
        },
        set(newValue: string) {
          this.current_file.name = newValue;
          this.$emit("update:modelValue", this.current_file);
        },
      },

      icon(): string {
        return this.file || this.firstLoad ? "fa-xmark" : "file-arrow-up";
      },
    },

    watch: {
      file(newValue: File) {
        if (newValue && !this.v$.current_file.$invalid) {
          this.uploadFile();
          this.$emit("update:modelValue", { ...this.current_file });
        } else {
          this.error = "";
          this.v$.current_file.$touch;
          this.$emit("update:modelValue", null);
        }
      },
    },

    methods: {
      async executeDriver(driver: any, data: any): Promise<any> {
        const driversClient = new Drivers();
        return driversClient.execute(driver.id, data);
      },

      async executeEventActions(eventName: string): Promise<any> {
        const eventActions = this.events[eventName];
        let result: any = {};

        for (let action of eventActions) {
          const response = await this.executeDriver(
            action.driver,
            getDataFromSchema(action.driver?.data_schema, {
              ...this.$props,
              ...this.$data,
              application: this.applicationStore.application,
            })
          );
          if (response.data) {
            result = merge(result, response.data);
          }
        }
        return result;
      },

      getFile(event: Event): void {
        const fileInput = <HTMLInputElement>event.target;
        let files = fileInput.files;
        this.file = files ? files[0] : null;

        if (this.file) {
          this.current_file = {
            name: this.fileName,
            content_type: this.file.type,
            comments: this.attributes.settings?.comments ?? "",
          };
        }
      },

      async getDocument() {
        if (!this.current_file.document_id) {
          return;
        }

        try {
          this.masterLayoutStore.toggleSpinner();
          const maxTries = 2;
          let attemptCounter = 1;

          let response = await this.executeEventActions("load");
          this.visualizationUrl = response.presigned_url || "";

          while (this.visualizationUrl === "" && attemptCounter <= maxTries) {
            this.visualizationUrl = response.presigned_url || "";
            setTimeout(async () => {
              response = await this.executeEventActions("load");
            }, (attemptCounter - 1) * 1000);

            attemptCounter++;
          }

          if (this.visualizationUrl) {
            window.open(this.visualizationUrl, "_blank");
          } else {
            baseToast({
              title: "Ha ocurrido un error al intentar visualizar el documento.",
              customOptions: { timeout: 5000 },
            });
          }

          this.masterLayoutStore.toggleSpinner();
        } catch (error) {
          this.masterLayoutStore.toggleSpinner();
          throw new DriverExecutionError(error);
        }
      },

      showFileSelectDialog() {
        (this.$refs.uploadInput as HTMLInputElement).value = "";

        if (!this.firstLoad) {
          (this.$refs.uploadInput as HTMLInputElement).click();
        } else {
          this.file = null;
          this.visualizationUrl = "";
          this.fileName = "";
          this.current_file = { name: "", content_type: "" };
          this.v$.current_file.$reset;

          if (this.firstLoad) {
            this.$emit("update:modelValue", null);
            this.firstLoad = false;
          }
        }
      },

      async uploadFile() {
        try {
          this.isWaiting = true;
          const response = await this.executeEventActions("drop");
          this.current_file.document_id = response.id;

          await axios.put(response.presigned_url, this.file, {
            headers: { "Content-Type": this.current_file.content_type },
          });

          this.isWaiting = false;

          this.$emit("update:modelValue", this.current_file);
        } catch ({ name, message }) {
          this.error = `[${name}]: ${message}`;
          this.isWaiting = false;
        }
      },
    },

    async created() {
      if (this.$attrs.modelValue || this.attributes.value) {
        this.current_file = this.$attrs.modelValue || this.attributes.value;
        if (this.current_file?.content_type) {
          this.firstLoad = true;
          this.fileName = this.current_file.name ?? this.attributes.settings?.name;
        }
      }
    },
  });
