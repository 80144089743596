
  import { computed, defineComponent, onMounted, ref, type WritableComputedRef } from "vue";

  export default defineComponent({
    name: "table-items-per-page-select",
    components: {},
    props: {
      itemsPerPage: { type: Number, default: 10 },
      itemsPerPageDropdownEnabled: {
        type: Boolean,
        required: false,
        default: true,
      },
      totalPages: {
        type: Number,
        required: true,
      },
      total: {
        type: Number,
        required: true,
      },

      currentPage: {
        type: Number,
        required: true,
      },
    },
    emits: ["update:itemsPerPage"],
    setup(props, { emit }) {
      const inputItemsPerPage = ref(10);

      onMounted(() => {
        inputItemsPerPage.value = props.itemsPerPage;
      });

      const itemsCountInTable: WritableComputedRef<number> = computed({
        get(): number {
          return props.itemsPerPage;
        },
        set(value: number): void {
          emit("update:itemsPerPage", value);
        },
      });

      const showingFrom = computed(() => {
        return props.currentPage === 1 ? 1 : (props.currentPage - 1) * props.itemsPerPage + 1;
      });

      const showingTo = computed(() => {
        const total: number = props.currentPage * props.itemsPerPage;

        return total > props.total ? props.total : total;
      });

      return {
        itemsCountInTable,
        showingFrom,
        showingTo,
      };
    },
  });
