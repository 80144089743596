import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11e88eb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "w-100 p-0 mb-0 list-style-none"
}
const _hoisted_3 = {
  key: 1,
  class: "fs-6 text-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["col", _ctx.classes(item)])
      }, [
        (_ctx.sourceIsArray(item?.props?.source || '-'))
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.props.source, (value, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass([index < item.props.source.length - 1 ? 'pb-2' : 'pb-0', "fs-6 text-gray-700"])
                }, _toDisplayString(value), 3))
              }), 256))
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item?.props?.source || "-"), 1))
      ], 2))
    }), 256)),
    (_ctx.$slots.actions && _ctx.haveActions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.actionClasses)
        }, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}