import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e27da5b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-control form-control-solid py-5"
}
const _hoisted_2 = { class: "align-middle mx-4" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["placeholder", "rows"]
const _hoisted_5 = {
  key: 0,
  class: "mt-1 mb-0"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isReadOnly)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.value), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          class: _normalizeClass(["form-control form-control-solid", { 'no-click': _ctx.attributes.disabled, [_ctx.textTransformClass]: _ctx.textTransformClass }]),
          placeholder: _ctx.attributes.placeholder,
          rows: _ctx.attributes.rows || 3
        }, null, 10, _hoisted_4), [
          [
            _vModelText,
            _ctx.value,
            void 0,
            { trim: true }
          ]
        ]),
        (_ctx.v$.value.$errors.length)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.value.$errors, (error) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: error.$uid,
                  class: "text-danger fw-bold mb-0"
                }, _toDisplayString(error.$message), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.serverErrors.length)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 1,
              class: _normalizeClass(["mb-0", { 'mt-1': !_ctx.v$.value.$errors.length }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: `${_ctx.attributes.name}-error-${index}`,
                  class: "text-danger fw-bold mb-0"
                }, [
                  _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_6)
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]))
}