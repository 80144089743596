import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: useStorage("user", {}) as any,
      token: useStorage("token", "") as unknown,
      tokenExpiresAt: useStorage("tokenExpiresAt", "") as unknown,
    };
  },

  actions: {
    can(constraints: string[][], permissions: any = null): boolean {
      let response = false;
      for (const permissionsGroup of constraints) {
        response = false;
        for (const path of permissionsGroup) {
          if (this.checkPermissionExistenceByPath(path, permissions)) {
            response = true;
            break;
          }
        }
        if (!response) {
          return false;
        }
      }
      return response;
    },

    checkPermissionExistenceByPath(path: string, permissions: any = null) {
      let permissionsSubset: any = permissions || this.user?.permissions;
      if (!permissionsSubset) {
        return false;
      }

      const pathTokens = path.split(".");
      pathTokens.every((token, index) => {
        permissionsSubset = permissionsSubset.hasOwnProperty(token) ? permissionsSubset[token] : null;
        return !(!permissionsSubset || index === pathTokens.length + 1);
      });

      return !!permissionsSubset;
    },

    clear() {
      this.$patch((state) => {
        state.user = null;
        state.token = "";
        state.tokenExpiresAt = "";
      });
    },

    isTokenValid() {
      if (!this.user || !this.tokenExpiresAt || !this.token) {
        return false;
      }

      return Math.floor(Date.now() / 1000) <= parseFloat(this.tokenExpiresAt as string);
    },
  },
});
