import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col d-grid ms-2 ps-0 ps-sm-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.titleClasses),
        innerHTML: _ctx.title
      }, null, 10, _hoisted_3),
      (_ctx.subtitle)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.subtitleClasses),
            innerHTML: _ctx.subtitle
          }, null, 10, _hoisted_4))
        : _createCommentVNode("", true)
    ])
  ]))
}