
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "VueProgressBar",

    props: {
      title: {
        type: String,
        required: true,
      },

      source: {
        type: String,
        required: true,
      },
    },
  });
