
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "HiddenInput",

    extends: BaseProcessComponent,

    created() {
      if (this.attributes.hasOwnProperty("source") && this.applicationStore.application) {
        this.$emit(
          "update:modelValue",
          getValueByPath({ application: this.applicationStore.application }, this.attributes.source.replace("$", ""))
        );
      } else if (this.attributes.hasOwnProperty("value")) {
        this.$emit("update:modelValue", this.attributes.value);
      }
    },

    watch: {
      attributes: {
        handler(newVal) {
          if (newVal?.hasOwnProperty("value")) {
            this.$emit("update:modelValue", newVal.value);
          }
        },
        deep: true,
      },
    },
  });
