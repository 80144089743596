interface Header {
  icon?: string;
  name: string;
  subTitle?: string | null;
}

interface Prop {
  title: string;
  source: string;
  class?: string;
  separator?: boolean;
  type?: string;
  cellClasses?: string;
  id?: string;
}

interface Component {
  props: Prop;
  component: string;
}

enum CardAppearances {
  card = "card",
  row = "row",
}

export { Header, Prop, Component, CardAppearances };
