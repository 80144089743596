import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-496fde14"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-0 ms-0"
}
const _hoisted_2 = {
  key: 1,
  class: "px-4"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "mt-1 mb-0"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_multi_select = _resolveComponent("v-multi-select")!

  return (_ctx.isReadOnly)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.attributes.classes?.container ?? '', "form-control form-control-solid"])
      }, [
        (_ctx.valueIsArray)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (option, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createElementVNode("label", null, _toDisplayString(option.label), 1)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", null, _toDisplayString(_ctx.selectedOptions?.label), 1)
            ]))
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_v_multi_select, {
          modelValue: _ctx.selectedOptions,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptions) = $event)),
          class: _normalizeClass({ 'no-click': _ctx.attributes.disabled }),
          classes: { container: _ctx.containerClasses, search: _ctx.searchInputClasses },
          "close-on-select": !_ctx.isMultiple,
          mode: _ctx.isMultiple ? 'tags' : 'single',
          object: true,
          options: _ctx.options,
          placeholder: _ctx.attributes.placeholder
        }, null, 8, ["modelValue", "class", "classes", "close-on-select", "mode", "options", "placeholder"]),
        (_ctx.v$.selectedOptions.$errors.length)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.selectedOptions.$errors, (error) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: error.$uid,
                  class: "text-danger fw-bold mb-0"
                }, _toDisplayString(error.$message), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.serverErrors.length)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 1,
              class: _normalizeClass(["mb-0", { 'mt-1': !_ctx.v$.selectedOptions.$errors.length }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: `${_ctx.attributes.name}-error-${index}`,
                  class: "text-danger fw-bold mb-0"
                }, [
                  _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_5)
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]))
}