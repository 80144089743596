import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16d8b1d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-control form-control-solid"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "mt-1 mb-0"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", null, _toDisplayString(_ctx.value ? _ctx.formattedBaseDate : ""), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_date_picker, _mergeProps(_ctx.customProperties, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            class: { 'no-click': _ctx.attributes.disabled },
            format: _ctx.dateFormat,
            "model-type": _ctx.modelType,
            placeholder: _ctx.modelType.toUpperCase()
          }), null, 16, ["modelValue", "class", "format", "model-type", "placeholder"]),
          (_ctx.v$.value.$errors.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.value.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: error.$uid,
                    class: "text-danger fw-bold mb-0"
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.serverErrors.length)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 1,
                class: _normalizeClass(["mb-0", { 'mt-1': !_ctx.v$.value.$errors.length }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `${_ctx.attributes.name}-error-${index}`,
                    class: "text-danger fw-bold mb-0"
                  }, [
                    _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_4)
                  ]))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ]))
  ]))
}