export default {
  quoteNumber: {
    label: "# cotización",
    id: "quoteNumber",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  createdDate: {
    label: "Fecha de creación",
    id: "createdDate",
    colClasses: "col d-none d-sm-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  clientName: {
    label: "Cliente",
    id: "clientName",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  productType: {
    label: "Producto",
    id: "productType",
    colClasses: "col d-none d-sm-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  identificationType: {
    label: "Identificación",
    id: "identificationType",
    colClasses: "col d-none d-lg-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  identificationNumber: {
    label: "# identificación",
    id: "identificationNumber",
    colClasses: "col d-none d-lg-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  user: {
    label: "Usuario",
    id: "user",
    colClasses: "col d-none d-sm-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  quoteStatus: {
    label: "Estado",
    id: "quoteStatus",
    colClasses: "col d-none d-sm-flex",
    headerClasses: "fs-5 text-gray-700 fw-bolder justify-content-start",
    cellClasses: "d-flex align-items-center py-3",
  },
  actions: {
    label: "Acciones",
    id: "actions",
    colClasses: "col",
    headerClasses: "text-center fs-5 text-gray-700 fw-bolder",
    cellClasses: "d-flex justify-content-center align-items-center",
  },
};
