
  import { defineComponent } from "vue";

  import { required } from "@/helpers/i18n-validators";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "SimpleSwitch",

    extends: BaseProcessComponent,

    validations() {
      return {
        value: {
          ...(this.isRequired && { required }),
          $autoDirty: true,
        },
      };
    },

    computed: {
      isRequired(): boolean {
        return !!this.attributes.required;
      },

      label(): string {
        return this.attributes.label || "";
      },

      value: {
        get(): boolean | string {
          return (this.$attrs.modelValue || this.attributes.value || false) as boolean | string;
        },

        set(value: boolean | string) {
          this.$emit("update:modelValue", value);
        },
      },

      onFalseValue(): boolean | string {
        if (!this.attributes.value_mapper) {
          return false;
        }

        return this.attributes.value_mapper.onFalse;
      },

      onTrueValue(): boolean | string {
        if (!this.attributes.value_mapper) {
          return true;
        }

        return this.attributes.value_mapper.onTrue;
      },
    },

    created() {
      if (this.attributes.hasOwnProperty("source") && this.applicationStore.application && !this.value) {
        this.$nextTick(() => {
          this.value = getValueByPath(
            { application: this.applicationStore.application },
            this.attributes.source.replace("$", "")
          );
        });
      } else if (
        !(this.$attrs.modelValue || this.attributes.value) ||
        this.$attrs.modelValue === this.attributes.value_mapper?.onFalse
      ) {
        this.$emit("update:modelValue", this.attributes.value_mapper ? this.attributes.value_mapper.onFalse : false);
      }
    },
  });
