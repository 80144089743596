
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "VueStatusBadge",

    props: {
      source: {
        type: String,
        required: true,
      },
    },
  });
