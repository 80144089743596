
  import { defineComponent } from "vue";

  import Popper from "vue3-popper";
  import { cloneDeep, keys, omit } from "lodash";

  import SwitchConditionalRender from "@/components/processComponents/SwitchConditionalRender.vue";
  import { baseToast } from "@/helpers/toastification";
  import { v4 as uuidv4 } from "uuid";

  export default defineComponent({
    name: "MultipleSwitchConditional",

    extends: SwitchConditionalRender,

    components: { Popper },

    data() {
      return {
        elements: [{}] as any[],
        formTemplates: [] as any[],
        myServeErrors: [] as any[],
        templatesErrors: [] as any[],
        templatesToRender: [] as any[],
        switchFormGroup: null as any,
      };
    },

    computed: {
      containerClasses(): string {
        return this.attributes?.settings?.template_container?.class || "bg-light border-top col-12 p-md-8 p-4";
      },

      footerClasses(): string {
        return (
          this.attributes?.settings?.component_footer?.class ||
          "bg-light col-12 p-md-8 p-4 d-flex justify-content-end border-top pt-4"
        );
      },

      headerClasses(): string {
        return (
          this.attributes?.settings?.component_header?.class ||
          'bg-danger-subtle bg-light border-top border-gray-400 col-12 px-md-10 px-6 py-6 py-7"'
        );
      },

      value: {
        get(): any {
          let newValue: any = cloneDeep(this.$attrs.modelValue || this.attributes.value || {});
          const hasSwitchComponent: any = newValue.hasOwnProperty(this.switchComponent?.attributes.name);

          if (hasSwitchComponent) {
            newValue.switchValue = newValue[this.switchComponent.attributes.name];
            this.selectedTemplate = this.findSelectedTemplate(this.getSelectedValue(newValue.switchValue));

            if (
              this.selectedTemplate &&
              newValue.hasOwnProperty(this.selectedTemplate.attributes.name) &&
              !this.hasElements
            ) {
              this.elements = newValue[this.selectedTemplate.attributes.name]?.length
                ? newValue[this.selectedTemplate.attributes.name]
                : [{}];
            }

            this.setTemplatesToRender();
            return newValue;
          }

          newValue = this.validateKeysValue(newValue);
          this.selectedTemplate = this.findSelectedTemplate(this.getSelectedValue(newValue.switchValue));
          if (
            this.selectedTemplate &&
            newValue.hasOwnProperty(this.selectedTemplate.attributes.name) &&
            !this.hasElements
          ) {
            this.elements = newValue[this.selectedTemplate.attributes.name];
            if (!this.elements.length && (this.isSelected || this.switchMapper)) {
              this.elements = [{}];
            }
          }

          this.setTemplatesToRender();
          return newValue;
        },

        set(value: any) {
          this.isSelected = this.getSelectedValue(value.switchValue);
          let switchValue: any = this.getSelectedValue(value.switchValue);
          let newVal: any = cloneDeep(omit(value, "switchValue"));
          if (this.switchComponent?.attributes.name) {
            newVal[this.switchComponent.attributes.name] = this.switchComponentValue(value);
          }

          if (this.switchMapper) {
            const templateOnTrue: any = this.findSelectedTemplate(true);
            const templateOnFalse: any = this.findSelectedTemplate(false);

            if (switchValue && value?.hasOwnProperty(templateOnFalse?.attributes.name)) {
              newVal = omit(newVal, templateOnFalse?.attributes.name);
              newVal[templateOnTrue.attributes.name] = [];
              this.elements = [{}];
            } else if (!switchValue && value?.hasOwnProperty(templateOnTrue?.attributes.name)) {
              newVal = omit(newVal, templateOnTrue?.attributes.name);
              newVal[templateOnFalse.attributes.name] = [];
              this.elements = [{}];
            } else if (!value?.hasOwnProperty(this.selectedTemplate?.attributes.name) && this.elements.length > 0) {
              newVal[this.findSelectedTemplate(this.isSelected).attributes.name] = [];
              this.elements = [{}];
            }
          } else {
            this.elements = [{}];
            if (switchValue) {
              newVal[this.findSelectedTemplate(true).attributes.name] = [];
            }

            newVal = switchValue ? newVal : omit(newVal, this.selectedTemplate?.attributes.name || "");
          }

          this.$emit("update:modelValue", newVal);
        },
      },

      hasElements(): boolean {
        return this.elements.length && this.elements.find((element: any) => keys(element).length);
      },

      templateOnFalse(): any {
        return this.findSelectedTemplate(false);
      },

      templateOnTrue(): any {
        return this.findSelectedTemplate(true);
      },

      switchMapperValue(): any {
        return this.isSelected ? this.switchMapper?.onTrue : this.switchMapper?.onFalse;
      },
    },

    watch: {
      "applicationStore.serverValidationErrors": {
        handler(newValue) {
          if (newValue) {
            if (this.selectedTemplate?.attributes?.name) {
              this.myServeErrors =
                this.applicationStore.getComponentErrorByName(
                  this.selectedTemplate.attributes.name,
                  this.attributes.name || this.parent,
                  this.indexPosition
                ) || [];

              this.templatesErrors = [];
              if (this.formTemplates.length) {
                for (let index = 0; index < this.formTemplates.length; index++) {
                  const templateErrors =
                    this.applicationStore.findComponentErrors(
                      newValue.errors?.data || [],
                      "",
                      this.selectedTemplate.attributes.name,
                      index
                    ) || [];

                  this.templatesErrors[index] = {
                    errors: templateErrors,
                  };
                }
              }
            }
          }
        },
      },

      elements: {
        handler(newVal) {
          if (newVal?.length && newVal.find((element: any) => keys(element).length)) {
            let data: any = {};
            if (this.switchComponent?.attributes.name) {
              data[this.switchComponent.attributes.name] = this.value.switchValue;
            }
            data[this.selectedTemplate.attributes.name] = newVal;

            if (!data[this.selectedTemplate.attributes.name].length) {
              this.isSelected = false;
            }

            let cleanValues: any = cloneDeep(omit({ ...this.value, ...data }, "switchValue"));
            const switchValue: any = this.getSelectedValue(this.value.switchValue);
            if (this.switchMapper) {
              if (switchValue && cleanValues?.hasOwnProperty(this.templateOnFalse?.attributes.name)) {
                cleanValues = omit(cleanValues, this.templateOnFalse?.attributes.name);
              } else if (!switchValue && cleanValues?.hasOwnProperty(this.templateOnTrue?.attributes.name)) {
                cleanValues = omit(cleanValues, this.templateOnTrue?.attributes.name);
              }
            }

            this.$emit("update:modelValue", cleanValues);
          }
        },
        deep: true,
      },

      selectedTemplate(newVal, oldVal) {
        if (
          newVal &&
          newVal.attributes.name !== oldVal?.attributes.name &&
          this.templatesToRender[0]?.attributes.name !== newVal?.attributes.name
        ) {
          this.templatesToRender = [];
          this.formTemplates = [];

          const component: any = {
            ...newVal,
            ...{ components: this.addIndexInTitle(newVal.components, 0) || [] },
          };

          this.templatesToRender.push(component);

          this.formTemplates = [
            {
              attributes: {},
              components: [{ ...component, ...{ attributes: { ...component.attributes, name: "" } } }],
              events: {},
              viewMode: this.componentViewMode,
              type: "form-templates",
              id: uuidv4(),
            },
          ];
        }
      },
    },

    created() {
      this.switchFormGroup = this.searchFormGroup(this.componentsToRender);
    },

    methods: {
      addElement() {
        let elementsHasValues: boolean = false;
        if (this.elements.length) {
          elementsHasValues = this.elements.every((element: any) => this.validateElementValues(element));
        }

        if (!this.elements.length || elementsHasValues) {
          if (
            !this.selectedTemplate?.attributes?.hasOwnProperty("max_items") ||
            this.elements.length < this.selectedTemplate.attributes.max_items
          ) {
            this.elements.push({});
            this.setTemplatesToRender();
          } else {
            baseToast(
              {
                title: "Ya se agregaron el máximo permitido de elementos",
                leadIcon: "warning",
                customOptions: {
                  timeout: 4000,
                },
              },
              "warning"
            );
          }
        } else if (!elementsHasValues) {
          baseToast(
            {
              title: "Debe ingresar información a cada elemento agregado",
              leadIcon: "warning",
              customOptions: {
                timeout: 4000,
              },
            },
            "warning"
          );
        }
      },

      addIndexInTitle(components: any[], index: number): any {
        const newComponents: any = cloneDeep(components);
        let response: any = null;
        for (const component of newComponents) {
          if (component.type === "form-title") {
            component.attributes.index = index + 1;
            response = newComponents;
            break;
          }

          response = this.addIndexInTitle(component.components, index);
          if (response) {
            component.components = cloneDeep(response);
            response = newComponents;
            break;
          }
        }

        return response || null;
      },

      getSelectedValue(value: any): boolean {
        let switchValue: any = value || false;
        if (this.switchMapper) {
          if (this.switchMapper["onFalse"] === switchValue) {
            switchValue = false;
          } else {
            switchValue = this.switchMapper["onTrue"] === switchValue;
          }
        }
        return switchValue;
      },

      removeElement(index: number) {
        this.elements.splice(index, 1);
        this.setTemplatesToRender();
      },

      searchFormGroup(elements: any[]) {
        let formGroup: any = null;
        const tempElements: any[] = cloneDeep(elements);

        for (const element of tempElements) {
          if (
            element.components.find(
              (component: any) => component.type === "simple-switch" && component.attributes.hasTemplate
            )
          ) {
            const newElements: any = element.components.map((component: any) => {
              if (component.type === "simple-switch" && component.attributes.hasTemplate) {
                component.attributes.name = "switchValue";
              }

              return component;
            });
            element.components = newElements;
            formGroup = element;
            break;
          }

          formGroup = this.searchFormGroup(element.components);
          if (formGroup) {
            break;
          }
        }

        return formGroup;
      },

      setTemplatesToRender() {
        if (this.selectedTemplate) {
          this.templatesToRender = [];
          this.formTemplates = [];
          for (let index = 0; index < this.elements.length; index++) {
            const component: any = {
              ...this.selectedTemplate,
              ...{ components: this.addIndexInTitle(this.selectedTemplate.components, index) },
            };

            this.templatesToRender.push(component);
            this.formTemplates.push({
              attributes: {},
              components: [{ ...component, ...{ attributes: { ...component.attributes, name: "" } } }],
              events: {},
              viewMode: this.componentViewMode,
              type: "form-templates",
              id: uuidv4(),
            });
          }
        }
      },

      validateElementValues(element: any): boolean {
        return keys(element).some((key: string) => {
          const value: any = element[key];
          return Array.isArray(value)
            ? !!value.length
            : (value !== null && value !== "") || (typeof value === "object" && Object.keys(value || {}).length);
        });
      },

      validateKeysValue(currentValue: any) {
        let newValue: any = cloneDeep(currentValue);
        if (!keys(newValue).length) {
          newValue.switchValue = this.switchMapper ? this.switchMapperValue : this.isSelected;
          this.selectedTemplate = this.findSelectedTemplate(this.isSelected);

          return newValue;
        }

        const hasTemplateOnTrue: boolean =
          this.templateOnTrue?.attributes?.name && newValue.hasOwnProperty(this.templateOnTrue.attributes.name);
        if (this.switchMapper) {
          if (hasTemplateOnTrue) {
            newValue.switchValue =
              newValue[this.templateOnTrue.attributes.name].length > 0 || this.isSelected
                ? this.switchMapper.onTrue
                : this.switchMapper.onFalse;
          } else {
            newValue.switchValue = this.switchMapper.onFalse;
          }
        } else {
          newValue.switchValue =
            (hasTemplateOnTrue && !!newValue[this.templateOnTrue.attributes.name].length) || this.isSelected;
        }

        return newValue;
      },

      switchComponentValue(value: any): any {
        return this.switchMapper ? value.switchValue : this.getSelectedValue(value.switchValue);
      },
    },
  });
