
  import { defineComponent } from "vue";
  import { mapStores } from "pinia";

  import CatalogSelect from "@/components/processComponents/CatalogSelect.vue";
  import ConditionalRenderer from "@/components/processComponents/ConditionalRenderer.vue";
  import ConditionalSelect from "@/components/processComponents/ConditionalSelect.vue";
  import CurrencyInput from "@/components/processComponents/CurrencyInput.vue";
  import DecimalInput from "@/components/processComponents/DecimalInput.vue";
  import DmClientProductDocuments from "@/components/processComponents/DmClientProductDocuments.vue";
  import FileUpload from "@/components/processComponents/FileUpload.vue";
  import FormContainer from "@/components/processComponents/FormContainer.vue";
  import FormTitle from "@/components/processComponents/FormTitle.vue";
  import HiddenInput from "@/components/processComponents/HiddenInput.vue";
  import IntegerInput from "@/components/processComponents/IntegerInput.vue";
  import MessageBox from "@/components/processComponents/MessageBox.vue";
  import NestedFormContainer from "@/components/processComponents/NestedFormContainer.vue";
  import QuoteResultsRender from "@/components/processComponents/QuoteResultsRender.vue";
  import SimpleCheckbox from "@/components/processComponents/SimpleCheckbox.vue";
  import SimpleDate from "@/components/processComponents/SimpleDate.vue";
  import SimpleDateTime from "@/components/processComponents/SimpleDateTime.vue";
  import SimpleEmail from "@/components/processComponents/SimpleEmail.vue";
  import SimpleInput from "@/components/processComponents/SimpleInput.vue";
  import SimpleNumber from "@/components/processComponents/SimpleNumber.vue";
  import SimplePhone from "@/components/processComponents/SimplePhone.vue";
  import SimpleLabel from "@/components/processComponents/SimpleLabel.vue";
  import SimpleSelect from "@/components/processComponents/SimpleSelect.vue";
  import SimpleSpan from "@/components/processComponents/SimpleSpan.vue";
  import SimpleTextarea from "@/components/processComponents/SimpleTextarea.vue";
  import SimpleTime from "@/components/processComponents/SimpleTime.vue";
  import SimpleSwitch from "@/components/processComponents/SimpleSwitch.vue";
  import RelationalSelect from "@/components/processComponents/RelationalSelect.vue";
  import { applicationViewModes } from "@/helpers/utils";
  import { useMasterLayoutStore } from "@/stores/MasterLayoutStore";

  export default defineComponent({
    name: "ProcessComponentsIterator",

    components: {
      CatalogSelect,
      ConditionalRenderer,
      ConditionalSelect,
      CurrencyInput,
      DecimalInput,
      DmClientProductDocuments,
      FileUpload,
      FormContainer,
      FormTitle,
      HiddenInput,
      IntegerInput,
      MessageBox,
      NestedFormContainer,
      QuoteResultsRender,
      SimpleCheckbox,
      SimpleDate,
      SimpleDateTime,
      SimpleEmail,
      SimpleInput,
      SimpleLabel,
      SimpleNumber,
      SimplePhone,
      SimpleSelect,
      SimpleSpan,
      SimpleSwitch,
      SimpleTextarea,
      SimpleTime,
      RelationalSelect,
    },

    props: {
      components: {
        type: Array,
        required: true,
        default: () => [],
      },

      indexPosition: {
        type: Number,
        default: () => -1,
      },

      layout: {
        type: String,
        required: false,
        default: () => "horizontal",
      },

      noClass: {
        type: Boolean,
        required: false,
        default: () => false,
      },

      parentComponentKey: {
        type: String,
        default: () => "",
      },

      viewMode: {
        type: String,
        required: false,
        default: () => applicationViewModes.visualization,
      },
    },

    computed: {
      ...mapStores(useMasterLayoutStore),

      childComponentsValue: {
        get(): any {
          return this.$attrs.modelValue || {};
        },

        set(value: any) {
          this.$emit("update:modelValue", value || this.value);
        },
      },

      isMobile(): boolean {
        return this.masterLayoutStore.mobile;
      },

      isVertical(): boolean {
        return this.layout === "vertical" || this.isMobile;
      },

      value: {
        get(): any {
          return this.$attrs.modelValue || {};
        },

        set(value: any) {
          this.$emit("update:modelValue", value || this.childComponentsValue);
        },
      },
    },

    methods: {
      childComponentsValueListener(event: any) {
        this.childComponentsValue = event;
      },

      valueListener(event: any, processComponentName: string) {
        this.value[processComponentName] = event;
        this.$emit("update:modelValue", { ...this.childComponentsValue, ...this.value });
      },
    },
  });
