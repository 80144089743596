
  import { defineComponent } from "vue";

  import { maxLength, minLength, required } from "@/helpers/i18n-validators";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import DriverExecutionError from "@/core/errors/DriverExecutrionError";
  import { getDataFromSchema, getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "RelationalInnerSelect",

    extends: BaseProcessComponent,

    data() {
      return {
        options: (this.attributes.options || null) as any[],
      };
    },

    validations() {
      return {
        selectedOptions: {
          ...(this.attributes.required && { required }),
          ...(this.attributes.minlength && {
            minLength: minLength(this.attributes.minlength),
          }),
          ...(this.attributes.maxlength && {
            maxLength: maxLength(this.attributes.maxlength),
          }),
          $autoDirty: true,
        },
      };
    },

    computed: {
      containerClasses(): string {
        return `multiselect ${this.attributes.classes?.container ?? ""}`;
      },

      isMultiple(): boolean {
        return this.attributes.multiple || false;
      },

      searchInputClasses(): string {
        return `multiselect-search ${this.attributes.classes?.search ?? ""}`;
      },

      selectedOptions: {
        get(): any {
          let modelValue: any = this.$attrs.modelValue || this.attributes.value || null;

          if (Array.isArray(modelValue)) {
            if (modelValue.length && modelValue[0].hasOwnProperty("detalle")) {
              return modelValue.map((item: any) => ({ label: item.detalle, value: item.codigo }));
            }
          } else if (modelValue) {
            return modelValue.hasOwnProperty("detalle")
              ? { label: modelValue.detalle, value: modelValue.codigo }
              : modelValue;
          } else if (this.isMultiple) {
            return [];
          }

          return null;
        },

        set(newVal: any) {
          let value: any = newVal;
          if (Array.isArray(newVal)) {
            value = newVal.map((item: any) => ({ codigo: item.value, detalle: item.label }));
          } else if (newVal && !newVal.hasOwnProperty("codigo")) {
            value = this.attributes.singleValue ? newVal.value : { codigo: newVal.value, detalle: newVal.label };
          }

          this.$emit("update:modelValue", value);
        },
      },

      valueIsArray(): any {
        return Array.isArray(this.selectedOptions);
      },
    },

    watch: {
      attributes: {
        handler(newVal) {
          if (newVal.hasOwnProperty("options")) {
            this.$nextTick(() => {
              this.options = newVal.options;
            });
          }
        },
        deep: true,
      },
    },

    async created() {
      if (this.attributes.hasOwnProperty("source") && !this.selectedOptions) {
        const path = this.attributes.source.replace("$", "");
        if (this.applicationStore.application) {
          const resolvedSource = getValueByPath({ application: this.applicationStore.application }, path);
          if (resolvedSource) {
            this.selectedOptions = resolvedSource;
          }
        }
      }

      if (!this.isReadOnly && this.events?.hasOwnProperty("load")) {
        await this.loadOptions();
      }
    },

    methods: {
      async loadOptions() {
        const driversClient = new Drivers();
        let action: any = {};

        for (action of this.events.load) {
          try {
            this.options = [{ label: "Cargando elementos...", value: null, disabled: true }];
            const response = await driversClient.execute(
              action.driver.id,
              getDataFromSchema(action.driver.data_schema, {})
            );
            this.options = response.data.catalogs ? response.data.catalogs[0].items : [];
            if (this.selectedOptions && !this.options?.some((option) => option.value === this.selectedOptions.value)) {
              this.selectedOptions = null;
            }
          } catch (error) {
            this.options = [{ label: "Error al cargar el catálogo ", value: null, disabled: true }];
            this.selectedOptions = null;
            throw new DriverExecutionError(error);
          }
        }
      },
    },
  });
