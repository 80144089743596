
  import { defineComponent } from "vue";
  import CardContextMenu from "@/components/base-card/card-partials/card-content/card-header/CardContextMenu.vue";

  export default defineComponent({
    name: "CardHeader",
    components: { CardContextMenu },

    props: {
      icon: {
        type: String,
        required: false,
      },
      subTitle: {
        type: String,
        required: false,
      },
      name: {
        type: String,
        required: true,
      },
      showContextMenu: {
        type: Boolean,
        default: () => true,
      },
    },
  });
