import axios, { AxiosInstance } from "axios";
import qs from "qs";

import { useUserStore } from "@/stores/UserStore";

export default class BaseApiClient {
  protected readonly baseURL: URL;
  protected readonly axiosInstance: AxiosInstance;
  protected userStore: any;

  constructor(basePath: string) {
    this.baseURL = new URL(basePath, process.env.VUE_APP_API_BASE_URL);
    this.userStore = useUserStore();

    this.axiosInstance = axios.create({
      baseURL: `${this.baseURL}`,
      headers: {
        "X-User-Id": this.userStore.user?.id,
        "X-Token": this.userStore.token,
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });

    this.axiosInstance.interceptors.response.use(
      async (response: any) => {
        if (response.headers.hasOwnProperty("x-token-expires-at")) {
          this.userStore.$patch({ tokenExpiresAt: response.headers["x-token-expires-at"] });
        }
        return response;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
}
