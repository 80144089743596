import { createApp, h } from "vue";
// @ts-ignore
import App from "./App.vue";

import { createPinia } from "pinia";
import mitt from "mitt";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Toast from "vue-toastification";
import "@/assets/scss/components/toastification.scss";
import VueSweetalert2 from "vue-sweetalert2";
import "@/assets/scss/components/sweetalert.scss";

import FormGroup from "@/components/processComponents/FormGroup.vue";
import FormTemplate from "@/components/processComponents/FormTemplate.vue";
import FormTemplates from "@/components/processComponents/FormTemplates.vue";
import MultipleSwitchConditional from "@/components/processComponents/MultipleSwitchConditional.vue";
import ProcessComponentsIterator from "@/components/processComponents/ProcessComponentsIterator.vue";
import ConditionalSelectForm from "@/components/processComponents/ConditionalSelectForm.vue";
import SwitchConditionalRender from "@/components/processComponents/SwitchConditionalRender.vue";
import router from "./router";
import VueDatePicker from "@/components/VueDatePicker.vue";
import VueMultiSelect from "@/components/VueMultiSelect.vue";
import FileUploadForm from "@/components/processComponents/FileUploadForm.vue";
import "@/assets/scss/style.scss";
import "@/plugins/fontawesome";

import { customErrorHandler } from "@/core/ErrorHandling";

export const vueApp = createApp(App);
const pinia: any = createPinia();

vueApp.config.errorHandler = customErrorHandler;
vueApp.config.globalProperties.$emitter = mitt();
vueApp.provide("emitter", mitt());

vueApp.use(pinia);
vueApp.use(Toast);
vueApp.use(VueSweetalert2);

vueApp.component("FormGroup", FormGroup);
vueApp.component("FormTemplate", FormTemplate);
vueApp.component("FormTemplates", FormTemplates);
vueApp.component("MultipleSwitchConditional", MultipleSwitchConditional);
vueApp.component("ProcessComponentsIterator", ProcessComponentsIterator);
vueApp.component("ConditionalSelectForm", ConditionalSelectForm);
vueApp.component("SwitchConditionalRender", SwitchConditionalRender);
vueApp.component("font-awesome-icon", FontAwesomeIcon);
vueApp.component("v-multi-select", VueMultiSelect);
vueApp.component("v-date-picker", VueDatePicker);
vueApp.component("FileUploadForm", FileUploadForm);

const render = (_hF: any) => h(App);
vueApp.use(router).use(render).mount("#app");
