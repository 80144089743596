
  import { defineComponent, PropType } from "vue";

  import Popper from "vue3-popper";

  import BaseCard from "@/components/base-card/BaseCard.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import { CardAppearances, Component, Header } from "@/components/base-card/CustomTypes";
  import { hoursFormatter, mapperDateFormatter } from "@/helpers/utils";

  export default defineComponent({
    name: "DocumentCard",

    components: { BaseCard, Popper },

    props: {
      appearance: {
        type: String,
        default: () => CardAppearances.card,
      },

      clientProductId: {
        type: String,
        required: true,
      },

      document: {
        type: Object,
        required: true,
      },

      folderId: {
        type: String,
        required: true,
      },

      folderPath: {
        type: String,
        required: true,
      },

      trail: {
        type: Array as PropType<string[]>,
        default: () => [],
      },

      tableConfig: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        header: {
          name: "",
          icon: "",
          subTitle: null,
        } as Header,
        body: {
          section: {
            props: {
              title: "Sección",
              source: "",
              separator: true,
              cellClasses: "d-flex justify-content-center align-items-center text-center py-3",
              id: "section",
            },
            component: "card-info-row",
          },
          documentalType: {
            props: {
              title: "Tipo documental",
              source: "",
              separator: true,
              cellClasses: "d-flex justify-content-center align-items-center text-center py-3",
              id: "documentalType",
            },
            component: "card-info-row",
          },
          createdDate: {
            props: {
              title: "Fecha de creación",
              separator: true,
              source: "",
              cellClasses: "d-flex justify-content-center align-items-center text-center py-3",
              id: "createdDate",
            },
            component: "card-info-row",
          },
        } as { [key: string]: Component },
        showProgress: false as boolean,
        visualizationUrl: null as string | null,
      };
    },

    computed: {
      isRowAppearance() {
        return this.appearance === CardAppearances.row;
      },
    },

    created() {
      this.header.name = this.document.name;
      this.header.icon = "file";

      this.body = {
        section: {
          props: {
            title: "Sección",
            source: this.trail[0] ?? "-",
            separator: true,
            id: "section",
          },
          component: "card-info-row",
        },
        documentalType: {
          props: {
            title: "Tipo documental",
            source: this.trail[1] ?? "-",
            separator: true,
            id: "documentalType",
          },
          component: "card-info-row",
        },
        createdDate: {
          props: {
            title: "Fecha de creación",
            separator: true,
            source: `${mapperDateFormatter(this.document.created_at)} ${hoursFormatter(this.document.created_at)}`,
            id: "createdDate",
          },
          component: "card-info-row",
        },
        ...(this.isRowAppearance && {
          documentName: {
            props: {
              title: "Nombre",
              source: this.document.name,
              id: "documentName",
            },
            component: "card-info-row",
          },
        }),
      };
    },

    watch: {
      appearance() {
        delete this.body["documentName"];

        if (this.isRowAppearance) {
          this.body["documentName"] = {
            props: {
              title: "Nombre",
              source: this.document.name,
              cellClasses: "d-flex py-3",
              id: "name",
            },
            component: "card-info-row",
          };
        }
      },
    },

    methods: {
      async openDocument() {
        const driversClient = new Drivers();
        try {
          this.showProgress = true;
          const response = await driversClient.execute(process.env.VUE_APP_DOCUMENTS_MANAGER_DRIVER_ID, {
            resource: "ClientProducts",
            method: "get_document_visualization_url",
            arguments: {
              client_product_id: this.clientProductId,
              folder_id: this.folderId,
              document_id: this.document.id,
              filters: {
                path: this.folderPath,
              },
            },
          });

          this.showProgress = false;
          this.visualizationUrl = response.data.presigned_url;
          window.open(this.visualizationUrl as string, "_blank");
        } catch (error: any) {
          this.showProgress = false;
          throw new Error(
            `Ha ocurrido un error obteniendo la url de visualización del documento "${this.document.name}"`
          );
        }
      },
    },
  });
