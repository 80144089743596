import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5e9648e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (component.attributes.name)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(component.type), _mergeProps({
              key: `${component.id}-${component.attributes.name}-${index}`,
              modelValue: _ctx.value[component.attributes.name],
              "onUpdate:modelValue": ($event: any) => ((_ctx.value[component.attributes.name]) = $event)
            }, component, {
              class: {
          'col-12': _ctx.isVertical && !_ctx.noClass,
          col: !_ctx.noClass,
        },
              parent: _ctx.parentComponentKey,
              "index-position": _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
              "view-mode": _ctx.viewMode,
              "onUpdate:modelValue": ($event: any) => (_ctx.valueListener($event, component.attributes.name))
            }), null, 16, ["modelValue", "onUpdate:modelValue", "class", "parent", "index-position", "view-mode"]))
          : (_openBlock(), _createBlock(_resolveDynamicComponent(component.type), _mergeProps({
              key: `${component.id}-${index}`,
              modelValue: _ctx.childComponentsValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.childComponentsValue) = $event))
            }, component, {
              class: {
          'col-12': _ctx.isVertical && !_ctx.noClass,
          col: !_ctx.noClass,
        },
              parent: _ctx.parentComponentKey,
              "index-position": _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
              "view-mode": _ctx.viewMode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.childComponentsValueListener($event)))
            }), null, 16, ["modelValue", "class", "parent", "index-position", "view-mode"]))
      ], 64))
    }), 256))
  ]))
}