
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "CardTab",

    props: {
      classes: {
        type: String,
        required: false,
        default: () => "col-6",
      },

      source: {
        type: String,
        required: true,
      },

      title: {
        type: String,
        required: false,
      },
    },
  });
