
  import { defineComponent, PropType } from "vue";

  import CardInfoRow from "@/components/base-card/card-partials/card-content/card-body/CardInfoRow.vue";
  import CardTab from "@/components/base-card/card-partials/card-content/card-body/CardTab.vue";
  import VueProgressBar from "@/components/VueProgressBar.vue";
  import { Component } from "@/components/base-card/CustomTypes";

  export default defineComponent({
    name: "CardBody",

    components: { CardInfoRow, CardTab, VueProgressBar },

    props: {
      body: {
        type: Object as PropType<{ [key: string]: Component }>,
        required: true,
      },
    },
  });
