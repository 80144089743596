import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_table = _resolveComponent("vue-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, index) => {
      return (_openBlock(), _createBlock(_component_vue_table, _mergeProps({
        key: `section-table-${index}`
      }, section), null, 16))
    }), 128))
  ]))
}