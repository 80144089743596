import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_process_components_iterator, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      class: _normalizeClass({ 'no-click': _ctx.attributes.disabled }),
      "parent-component-key": _ctx.attributes.name || _ctx.parent,
      components: _ctx.components,
      "index-position": _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
      "view-mode": _ctx.componentViewMode
    }, null, 8, ["modelValue", "class", "parent-component-key", "components", "index-position", "view-mode"]),
    (_ctx.serverErrors.length)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass(["mb-0", { 'mt-1': _ctx.serverErrors.length }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${_ctx.attributes.name}-error-${index}`,
              class: "text-danger fw-bold mb-0"
            }, [
              _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_1)
            ]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}