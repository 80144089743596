
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";

  export default defineComponent({
    name: "MessageBox",

    extends: BaseProcessComponent,

    data() {
      return {
        content: (this.attributes.value || "") as string,
      };
    },
  });
