import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8389656"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title-border-bottom"
}
const _hoisted_2 = { class: "d-flex flex-column justify-content-center pt-md-6 pb-md-5 px-md-12 pt-4 pb-4 px-5" }
const _hoisted_3 = { class: "fw-bold text-gray-800 fs-1 lh-base" }
const _hoisted_4 = {
  key: 0,
  class: "fw-bold text-gray-500 fs-5"
}
const _hoisted_5 = {
  key: 0,
  class: "ps-5 mb-3"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container bg-white", _ctx.attributes.class])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
            (_ctx.subtitle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.subtitle), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["form py-md-10 px-md-12 py-4 px-5", { 'no-click': _ctx.attributes.disabled }])
    }, [
      (_ctx.serverErrors.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `${_ctx.attributes.name}-error-${index}`,
                class: "text-danger fw-bold mb-0"
              }, [
                _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_6)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_process_components_iterator, {
        components: _ctx.components,
        "index-position": _ctx.indexPosition >= 0 ? _ctx.indexPosition : -1,
        "view-mode": _ctx.componentViewMode,
        "no-class": true,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, null, 8, ["components", "index-position", "view-mode", "modelValue"])
    ], 2)
  ], 2))
}