
  import { defineComponent } from "vue";

  import SimpleDate from "@/components/processComponents/SimpleDate.vue";
  import { mapperDateFormatter } from "@/helpers/utils";

  export default defineComponent({
    name: "SimpleDateTime",

    extends: SimpleDate,

    computed: {
      format(): any {
        return (date: any) => {
          let newDate: any = !Array.isArray(date) ? new Date(date) : "";
          return Array.isArray(date)
            ? date
                .map((singleDate: any) => {
                  newDate = new Date(singleDate);
                  return `${mapperDateFormatter(singleDate)} ${newDate?.getHours()}:${newDate?.getMinutes()}`;
                })
                .toString()
            : `${mapperDateFormatter(date)} ${newDate?.getHours()}:${newDate?.getMinutes()}`;
        };
      },

      modelType(): string {
        return "dd-MMM-yyyy HH:mm";
      },
    },
  });
