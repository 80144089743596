import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "multiselect-multiple-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createBlock(_component_multiselect, _mergeProps(_ctx.$attrs, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    "no-results-text": _ctx.noResultsText,
    "no-options-text": _ctx.noOptionsText,
    placeholder: _ctx.placeholder,
    searchable: _ctx.searchable,
    onFocusin: _ctx.showOptions,
    ref: "multiselect"
  }), {
    multiplelabel: _withCtx(({ values }) => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(values.length) + " opciones seleccionadas", 1)
    ]),
    _: 1
  }, 16, ["modelValue", "no-results-text", "no-options-text", "placeholder", "searchable", "onFocusin"]))
}