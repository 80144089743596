import Swal from "sweetalert2";

import BaseError from "@/core/errors/BaseError";
import { baseToast } from "@/helpers/toastification";

export default class ToastException extends BaseError {
  constructor(message: string, options?: any) {
    super(message);
    this.options = options;
  }

  render() {
    if (!Swal.isVisible()) {
      baseToast({
        title: this.message,
        leadIcon: "fa-triangle-exclamation",
        customOptions: {
          hideProgressBar: false,
          ...this.options,
        },
      });
    }
  }
}
