import { RouteRecordRaw } from "vue-router";

export interface BreadCrumb {
  icon?: string;
  text?: string;
  tooltipText?: string;
  to?: RouteRecordRaw;
}

export const getDataFromSchema = (schema: any, context: any): any => {
  let data: any = {};
  if (schema.hasOwnProperty("type") && schema.type === "object") {
    data = firstLevelObjects(schema, context);
  }

  data = setDataValue(schema, context, data);
  return data;
};

function firstLevelObjects(schema: any, context: any) {
  if (schema.hasOwnProperty("source") && schema.source.length > 0) {
    let pathSource = schema.source.split(".")[0].replace("$", "");
    return getValueByPath(
      pathSource == "self" ? context : context[pathSource],
      schema.source.split(".").splice(1).join(".")
    );
  } else {
    return getDataFromSchema(schema.properties, context);
  }
}

const setDataValue = (schema: any, context: any, data: any): any => {
  for (const key in schema) {
    if (schema[key].hasOwnProperty("source") && schema[key].source.length > 0) {
      let pathSource = schema[key].source.split(".")[0].replace("$", "");
      let value =
        getValueByPath(
          pathSource === "self" ? context : context[pathSource],
          schema[key].source.split(".").splice(1).join(".")
        ) ?? schema[key].default;
      data[key] = value ?? "";
    } else if (schema[key].hasOwnProperty("type") && schema[key].type == "object") {
      data[key] = getDataFromSchema(schema[key].properties, context);
    } else if (schema[key].hasOwnProperty("default")) {
      data[key] = schema[key].default;
    }
  }

  return data;
};

export const getValueByPath = (context: any, path: string): any => {
  const path_tokens: Array<string> = path.split(".");
  let value: any = context;
  for (let token of path_tokens) {
    value = value?.hasOwnProperty(token) ? value[token] : null;
    if (!value) {
      return value;
    }
  }
  return value;
};

export const dateFormatter = (date: string, options: any = {}) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString("en-GB", options);
};

export const hoursFormatter = (date: any, options: any = {}) => {
  const newDate = new Date(date);
  return newDate
    .toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      ...options,
    })
    .toUpperCase();
};

export const mapperDateFormatter = (value: string) => {
  const monthMapper: string[] = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

  const date: Date = new Date(value);
  const days: string = `${date.getDate()}`.padStart(2, "0");

  return `${days}-${monthMapper[date.getMonth()]}-${date.getFullYear()}`;
};

export const applicationViewModes: any = {
  modification: "modification",
  create: "create",
  visualization: "visualization",
};

export const relatedRolesKeys: Array<string> = ["admin", "supervisor", "broker"];
