import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10e75042"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-0 ms-0"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "form-check form-switch form-check-custom form-check-solid form-check-danger me-3" }
const _hoisted_5 = {
  key: 0,
  class: "mt-1 mb-0"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_multi_select = _resolveComponent("v-multi-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.attributes.classes?.container ?? '', "form-control form-control-solid"])
        }, [
          (_ctx.valueIsArray)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (option, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createElementVNode("label", null, _toDisplayString(option), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("label", null, _toDisplayString(_ctx.value), 1)
              ]))
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex align-items-center", { 'no-click': _ctx.attributes.disabled }])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkboxValue) = $event)),
                class: "form-check-input",
                type: "checkbox"
              }, null, 512), [
                [_vModelCheckbox, _ctx.checkboxValue]
              ])
            ]),
            (_ctx.checkboxValue)
              ? (_openBlock(), _createBlock(_component_v_multi_select, {
                  key: 0,
                  modelValue: _ctx.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
                  mode: _ctx.attributes.multiple ? 'tags' : 'single',
                  class: _normalizeClass({ 'no-click': _ctx.attributes.disabled }),
                  classes: { container: _ctx.containerClasses, search: _ctx.searchInputClasses },
                  "close-on-select": false,
                  options: _ctx.options
                }, null, 8, ["modelValue", "mode", "class", "classes", "options"]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.v$.value.$errors.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.value.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: error.$uid,
                    class: "text-danger fw-bold mb-0"
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.serverErrors.length)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 1,
                class: _normalizeClass(["mb-0", { 'mt-1': !_ctx.v$.value.$errors.length }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serverErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `${_ctx.attributes.name}-error-${index}`,
                    class: "text-danger fw-bold mb-0"
                  }, [
                    _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_6)
                  ]))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ]))
  ]))
}