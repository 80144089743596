
  import { defineComponent } from "vue";

  import { helpers, minValue, maxValue } from "@vuelidate/validators";

  import SimpleInput from "@/components/processComponents/SimpleInput.vue";
  import { numeric } from "@/helpers/i18n-validators";

  export default defineComponent({
    name: "SimpleNumber",

    extends: SimpleInput,

    validations() {
      return {
        value: {
          ...this.rules(),
          minValue: helpers.withMessage(`El valor no puede ser menor a 0`, minValue(0)),
          ...(this.attributes.min && {
            minValue: helpers.withMessage(
              `El valor no puede ser menor a ${this.attributes.min}`,
              minValue(this.attributes.min)
            ),
          }),
          ...(this.attributes.max && {
            maxValue: helpers.withMessage(
              `El valor no puede ser mayor a ${this.attributes.max}`,
              maxValue(this.attributes.max)
            ),
          }),
          numeric,
        },
      };
    },

    computed: {
      value: {
        get() {
          let newValue: any = this.$attrs.modelValue;
          if (newValue === "0") {
            this.$emit("update:modelValue", 0);
          } else if (typeof newValue !== "number") {
            newValue = Number(newValue) || "";
            if (newValue) {
              this.$emit("update:modelValue", newValue);
            }
          }

          return newValue;
        },

        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },
    },
  });
