import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67b93876"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center rounded-3 me-4 p-2 header-icon"
}
const _hoisted_3 = { class: "header-title-container row" }
const _hoisted_4 = {
  key: 0,
  class: "text-gray-500 fw-bold col-12"
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-end col-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_card_context_menu = _resolveComponent("card-context-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex align-items-center", [_ctx.showContextMenu ? 'col-10' : 'col']])
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_font_awesome_icon, {
              icon: _ctx.icon,
              size: "2xl"
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.subTitle)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.subTitle), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["text-gray-800 fw-bold col-12", { 'fs-3': !_ctx.subTitle, 'fs-4': _ctx.subTitle }])
        }, _toDisplayString(_ctx.name), 3)
      ])
    ], 2),
    (_ctx.showContextMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_card_context_menu, null, {
            options: _withCtx(() => [
              _renderSlot(_ctx.$slots, "options", {}, undefined, true)
            ]),
            _: 3
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}