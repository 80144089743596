import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAnglesLeft,
  faAngleLeft,
  faAngleRight,
  faAnglesRight,
  faArrowDown,
  faArrowRight,
  faArrowRightFromBracket,
  faBars,
  faBarsStaggered,
  faBook,
  faBorderAll,
  faCar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleExclamation,
  faEraser,
  faEye,
  faFile,
  faFileArrowUp,
  faGear,
  faHome,
  faInbox,
  faMagnifyingGlass,
  faPen,
  faPeopleRoof,
  faPlus,
  faShare,
  faTrash,
  faTriangleExclamation,
  faUser,
  faUserGear,
  faUserLock,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(faAnglesLeft);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAnglesRight);
library.add(faArrowRightFromBracket);
library.add(faArrowDown);
library.add(faArrowRight);
library.add(faBars);
library.add(faBarsStaggered);
library.add(faBook);
library.add(faBorderAll);
library.add(faCar);
library.add(faCheck);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faCircle);
library.add(faCircleExclamation);
library.add(faEraser);
library.add(faEye);
library.add(faFile);
library.add(faFileArrowUp);
library.add(faGear);
library.add(faHome);
library.add(faInbox);
library.add(faMagnifyingGlass);
library.add(faPen);
library.add(faPeopleRoof);
library.add(faPlus);
library.add(faShare);
library.add(faTrash);
library.add(faTriangleExclamation);
library.add(faUser);
library.add(faUserGear);
library.add(faUserLock);
library.add(faXmark);
