import BaseApiClient from "./BaseApiClient";

export default class Drivers extends BaseApiClient {
  constructor() {
    super("v1/drivers");
  }

  read(driverId: string) {
    return this.axiosInstance({
      url: `/${driverId}`,
      method: "get",
    });
  }

  execute(driverId: string, data: any) {
    return this.axiosInstance({
      url: `/${driverId}/actions/execute`,
      data: data,
      method: "post",
    });
  }
}
