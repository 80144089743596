import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-778475df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.body, (component, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(component.component), _mergeProps({ key: index }, component.props), null, 16))
    }), 128))
  ]))
}