import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-179d1653"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-wrapper"
}
const _hoisted_2 = { class: "card h-100" }
const _hoisted_3 = {
  key: 1,
  class: "card-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_header = _resolveComponent("card-header")!
  const _component_card_body = _resolveComponent("card-body")!
  const _component_card_footer = _resolveComponent("card-footer")!
  const _component_card_row = _resolveComponent("card-row")!

  return (_ctx.isCardAppearance)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.header)
            ? (_openBlock(), _createBlock(_component_card_header, {
                key: 0,
                name: _ctx.header.name,
                icon: _ctx.header.icon,
                "sub-title": _ctx.header.subTitle,
                "show-context-menu": _ctx.showContextMenu
              }, _createSlots({ _: 2 }, [
                (_ctx.$slots.options)
                  ? {
                      name: "options",
                      fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "options", {}, undefined, true)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["name", "icon", "sub-title", "show-context-menu"]))
            : _createCommentVNode("", true),
          _createVNode(_component_card_body, { body: _ctx.body }, null, 8, ["body"]),
          (_ctx.$slots.actions)
            ? (_openBlock(), _createBlock(_component_card_footer, { key: 1 }, {
                actions: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_card_row, {
          body: _ctx.body,
          config: _ctx.config
        }, _createSlots({ _: 2 }, [
          (_ctx.$slots.actions)
            ? {
                name: "actions",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["body", "config"])
      ]))
}