import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  for: "currency",
  class: "form-control form-control-solid"
}
const _hoisted_2 = {
  for: "value",
  class: "form-control form-control-solid col ms-md-2 ms-0 mt-md-0 mt-4"
}
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "mt-1 mb-0"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_multi_select = _resolveComponent("v-multi-select")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.attributes.class)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.currencyAttributes.class)
          }, [
            _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.currencyValue?.label ?? ""), 1)
          ], 2),
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.maskedValue), 1)
        ], 2))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _normalizeProps(_guardReactiveProps(_ctx.attributes)), [
            _createVNode(_component_v_multi_select, {
              modelValue: _ctx.currencyValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currencyValue) = $event)),
              class: _normalizeClass(_ctx.currencyAttributes.class),
              object: true,
              options: _ctx.options,
              placeholder: _ctx.currencyAttributes.placeholder
            }, null, 8, ["modelValue", "class", "options", "placeholder"]),
            _withDirectives(_createElementVNode("input", _mergeProps(_ctx.amountAttributes, {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.amountValue) = $event)),
              class: "form-control form-control-solid",
              "data-maska": "0.99",
              "data-maska-tokens": "0:\\d:multiple|9:\\d:optional",
              autocomplete: "off",
              placeholder: _ctx.amountAttributes.placeholder || 'Escriba sus datos'
            }), null, 16, _hoisted_3), [
              [_vModelDynamic, _ctx.amountValue],
              [_directive_maska, _ctx.bindedObject, _ctx.currencyOptions]
            ])
          ], 16),
          (_ctx.v$.componentValues.$errors.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.componentValues.$errors, (error) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: error.$uid,
                    class: "text-danger fw-bold mb-0"
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.myServerErrors.length)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 1,
                class: _normalizeClass(["mb-0", { 'mt-1': !_ctx.v$.componentValues.$errors.length }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myServerErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `${_ctx.currencyAttributes.name}-${_ctx.amountAttributes.name}-error-${index}`,
                    class: "text-danger fw-bold mb-0"
                  }, [
                    _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_5)
                  ]))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}