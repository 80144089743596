import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_2 = { class: "m-0" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "d-flex justify-content-end"
}
const _hoisted_5 = {
  key: 0,
  class: "px-2 bg-light remove-icon-container"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "mb-4 ps-3 ps-md-2" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_components_iterator = _resolveComponent("process-components-iterator")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_popper = _resolveComponent("popper")!
  const _component_form_templates = _resolveComponent("form-templates")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'no-click': _ctx.attributes.disabled })
  }, [
    (_ctx.switchFormGroup)
      ? (_openBlock(), _createBlock(_component_process_components_iterator, {
          key: 0,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          components: _ctx.switchFormGroup.components,
          "view-mode": _ctx.componentViewMode
        }, null, 8, ["modelValue", "components", "view-mode"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.selectedTemplate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.myServeErrors.length)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.headerClasses)
                  }, [
                    _createElementVNode("ul", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myServeErrors, (error, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: `${_ctx.selectedTemplate.attributes.name}-error-${index}`,
                          class: "text-danger fw-bold mb-0"
                        }, [
                          _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_3)
                        ]))
                      }), 128))
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.containerClasses)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements.length, (index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `elements-${_ctx.selectedTemplate.attributes.name}-${index}`
                  }, [
                    (_ctx.elements.length > 1 && index > 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (!_ctx.isReadOnly)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_popper, {
                                  content: "Remover",
                                  placement: "top",
                                  class: "dark-popper",
                                  arrow: "",
                                  hover: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "btn btn-remove-icon bg-gray-600 p-2 rounded-1 text-white d-flex align-items-center justify-content-center",
                                      onClick: ($event: any) => (_ctx.removeElement(index - 1))
                                    }, [
                                      _createVNode(_component_font_awesome_icon, { icon: "trash" })
                                    ], 8, _hoisted_6)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.templatesErrors.length && _ctx.templatesErrors[index - 1]?.errors)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("ul", _hoisted_8, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatesErrors[index - 1].errors, (error, indexError) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: `template-error-${indexError}`,
                                class: "text-danger fw-bold mb-0"
                              }, [
                                _createElementVNode("div", { innerHTML: error }, null, 8, _hoisted_9)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(), _createBlock(_component_form_templates, _mergeProps(_ctx.formTemplates[index - 1], {
                      modelValue: _ctx.elements[index - 1],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.elements[index - 1]) = $event),
                      key: `ft-${_ctx.selectedTemplate.attributes.name}-${index}`,
                      parent: _ctx.selectedTemplate.attributes.name || _ctx.parent,
                      indexPosition: index - 1
                    }), null, 16, ["modelValue", "onUpdate:modelValue", "parent", "indexPosition"]))
                  ]))
                }), 128))
              ], 2),
              (!_ctx.isReadOnly)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.footerClasses)
                  }, [
                    _createVNode(_component_popper, {
                      content: "Agregar",
                      placement: "top",
                      class: "dark-popper",
                      arrow: "",
                      hover: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "btn btn-plus-icon bg-danger p-2 rounded-1 text-white d-flex align-items-center justify-content-center",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addElement && _ctx.addElement(...args)))
                        }, [
                          _createVNode(_component_font_awesome_icon, { icon: "plus" })
                        ])
                      ]),
                      _: 1
                    })
                  ], 2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}