export default {
  documentName: {
    label: "Nombre",
    id: "documentName",
    colClasses: "col-9 col-md-4",
    headerClasses: "fs-5 text-gray-700 fw-bolder p-3",
    cellClasses: "d-flex align-items-start align-items-center py-3",
  },
  section: {
    label: "Sección",
    id: "section",
    colClasses: "col",
    headerClasses: "d-none d-md-flex fs-5 text-gray-700 fw-bolder p-3",
    cellClasses: "d-none d-md-flex justify-content-start align-items-center py-3",
  },
  documentalType: {
    label: "Tipo documental",
    colClasses: "col-3",
    headerClasses: "d-none d-md-flex fs-5 text-gray-700 fw-bolder p-3",
    cellClasses: "d-none d-md-flex justify-content-start align-items-center py-3",
    id: "documentalType",
  },
  createdDate: {
    label: "Fecha de creación",
    id: "createdDate",
    colClasses: "col",
    headerClasses: "d-none d-md-flex fs-5 text-gray-700 fw-bolder p-3",
    cellClasses: "d-none d-md-flex justify-content-start align-items-center py-3",
  },
  actions: {
    label: "Acciones",
    id: "actions",
    colClasses: "col-3 col-md-1",
    headerClasses: "text-center fs-5 text-gray-700 fw-bolder p-3",
    cellClasses: "d-flex justify-content-center align-items-center",
  },
};
