
  import { defineComponent, PropType } from "vue";

  import DataTable from "@/components/kt-datatable/KTDataTable.vue";

  export default defineComponent({
    name: "VueTable",

    components: { DataTable },

    props: {
      title: {
        type: String,
        required: true,
      },

      subtitle: {
        type: String,
        required: false,
      },

      headers: {
        type: Array as PropType<string[]>,
        required: true,
      },

      rows: {
        type: Array as PropType<any[]>,
        required: true,
      },
    },

    data() {
      return {
        sortSettings: {
          columnLabel: 0,
          order: "asc",
        },
      };
    },

    computed: {
      dataTableHeaders() {
        return this.headers.map((header: any, index: number) => {
          return { columnName: header, columnLabel: index, sortEnabled: false };
        });
      },

      dataTableRows() {
        let rows: any = [];

        this.rows.forEach((row: any) => {
          if (row.length < 1) {
            return;
          }

          let cells: any = {};

          row.forEach((cell: any, index: number) => {
            cells[index] = cell;
          });

          rows.push(cells);
        });

        return rows;
      },
    },

    methods: {
      onSort(sort: any) {
        this.sortSettings["columnLabel"] = sort.label;
        this.sortSettings["order"] = sort.order;
      },

      sortItems(a: any, b: any) {
        if (this.sortSettings["order"] === "asc") {
          if (a[this.sortSettings.columnLabel] > b[this.sortSettings.columnLabel]) {
            return 1;
          } else if (a[this.sortSettings.columnLabel] === b[this.sortSettings.columnLabel]) {
            return 0;
          }

          return -1;
        }

        if (a[this.sortSettings.columnLabel] > b[this.sortSettings.columnLabel]) {
          return -1;
        } else if (a[this.sortSettings.columnLabel] === b[this.sortSettings.columnLabel]) {
          return 0;
        }

        return 1;
      },
    },
  });
