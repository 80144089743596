import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex flex-column px-0 info-row" }
const _hoisted_2 = { class: "d-flex justify-content-between mt-2" }
const _hoisted_3 = { class: "text-gray-600 fw-bolder fs-5 mx-4 text-start px-0 col-4" }
const _hoisted_4 = {
  key: 1,
  class: "text-gray-900 fw-bolder fs-5 text-end col-7"
}
const _hoisted_5 = {
  key: 2,
  class: "w-100 pe-0 mb-0 list-style-none"
}
const _hoisted_6 = {
  key: 0,
  class: "separator separator-dashed mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.type)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type), {
            key: 0,
            source: _ctx.source
          }, null, 8, ["source"]))
        : (typeof _ctx.source === 'string')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.source), 1))
          : (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source, (value, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass([index < _ctx.source.length - 1 ? 'pb-2' : 'pb-0', "w-100 text-gray-900 fw-bolder fs-5 text-end col-7"])
                }, _toDisplayString(value), 3))
              }), 256))
            ]))
    ]),
    (_ctx.separator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}