import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end" }
const _hoisted_2 = { class: "dataTables_paginate paging_simple_numbers" }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", {
          class: _normalizeClass(["paginate_button page-item", { disabled: _ctx.isInFirstPage }]),
          style: _normalizeStyle({ cursor: !_ctx.isInFirstPage ? 'pointer' : 'auto' })
        }, [
          _createElementVNode("a", {
            class: "page-link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickFirstPage && _ctx.onClickFirstPage(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angles-left'] })
          ])
        ], 6),
        _createElementVNode("li", {
          class: _normalizeClass(["paginate_button page-item", { disabled: _ctx.isInFirstPage }]),
          style: _normalizeStyle({ cursor: !_ctx.isInFirstPage ? 'pointer' : 'auto' })
        }, [
          _createElementVNode("a", {
            class: "page-link",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickPreviousPage && _ctx.onClickPreviousPage(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-left'] })
          ])
        ], 6),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, i) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["paginate_button page-item", {
            active: _ctx.isPageActive(page.name),
          }]),
            style: _normalizeStyle({ cursor: !page.isDisabled ? 'pointer' : 'auto' }),
            key: i
          }, [
            _createElementVNode("a", {
              class: "page-link",
              onClick: ($event: any) => (_ctx.onClickPage(page.name))
            }, _toDisplayString(page.name), 9, _hoisted_4)
          ], 6))
        }), 128)),
        _createElementVNode("li", {
          class: _normalizeClass(["paginate_button page-item", { disabled: _ctx.isInLastPage }]),
          style: _normalizeStyle({ cursor: !_ctx.isInLastPage ? 'pointer' : 'auto' })
        }, [
          _createElementVNode("a", {
            class: "paginate_button page-link",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickNextPage && _ctx.onClickNextPage(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-right'] })
          ])
        ], 6),
        _createElementVNode("li", {
          class: _normalizeClass(["paginate_button page-item", { disabled: _ctx.isInLastPage }]),
          style: _normalizeStyle({ cursor: !_ctx.isInLastPage ? 'pointer' : 'auto' })
        }, [
          _createElementVNode("a", {
            class: "paginate_button page-link",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickLastPage && _ctx.onClickLastPage(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angles-right'] })
          ])
        ], 6)
      ])
    ])
  ]))
}