import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff1dffc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column h-100 px-5 py-4 rounded-3 tab" }
const _hoisted_2 = { class: "text-gray-800 fw-bolder fs-6 lh-sm" }
const _hoisted_3 = { class: "text-gray-500 fw-bold fs-6 lh-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tab-wrapper mb-5", _ctx.classes])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.source), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ])
  ], 2))
}