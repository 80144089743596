
  import { defineComponent } from "vue";

  import { mapStores } from "pinia";
  import { required, maxLength, minLength } from "@/helpers/i18n-validators";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import { getDataFromSchema } from "@/helpers/utils";
  import { useApplicationStore } from "@/stores/ApplicationStore";

  let driversClient = null as Drivers | null;

  export default defineComponent({
    name: "ConditionalSelect",

    extends: BaseProcessComponent,

    data() {
      return {
        checkboxValue: false as boolean,
        options: [] as Array<any>,
      };
    },

    validations() {
      return {
        value: {
          ...(this.attributes.required && { required }),
          ...(this.attributes.maxlength && { maxLength: maxLength(this.attributes.maxlength) }),
          ...(this.attributes.minlength && { minLength: minLength(this.attributes.minlength) }),
          $autoDirty: true,
        },
      };
    },

    computed: {
      ...mapStores(useApplicationStore),

      containerClasses(): string {
        return `multiselect ${this.attributes.classes?.container ?? ""}`;
      },

      searchInputClasses(): string {
        return `multiselect-search ${this.attributes.classes?.search ?? ""}`;
      },

      value: {
        get() {
          const modelValue: any = this.$attrs.modelValue || this.attributes.value || null;
          this.checkboxValue = !!modelValue;
          return modelValue;
        },

        set(value: any) {
          const result: any = Array.isArray(value) && value.length === 0 ? null : value;
          this.$emit("update:modelValue", result);
        },
      },

      valueIsArray(): any {
        return Array.isArray(this.value);
      },
    },

    watch: {
      async checkboxValue(newVal) {
        if (!newVal) {
          this.$emit("update:modelValue", null);
          return;
        }

        if (this.viewMode !== this.viewModes.visualization) {
          if (this.attributes.options?.length) {
            this.options = this.attributes.options;
          } else if (this.events.enabled) {
            await this.enabled();
          }
        }
      },
    },

    created() {
      driversClient = new Drivers();

      if (this.value) {
        this.checkboxValue = true;
      }
    },

    methods: {
      async executeEventActions(eventName: string) {
        const eventActions = this.events[eventName];
        let result: any = [];
        for (let action of eventActions) {
          const response = await driversClient?.execute(
            action.driver,
            getDataFromSchema(action.driver?.data_schema, {
              ...this.$props,
              ...this.$data,
              application: this.applicationStore.application,
            })
          );
          if (response?.data) {
            result = result.concat(response.data);
          }
        }
        return result;
      },

      async enabled() {
        try {
          this.options = [];
          const response: any[] = await this.executeEventActions("enabled");
          if (response?.length) {
            this.options = response;
          }
        } finally {
          if (!this.options.length) {
            this.checkboxValue = false;
          }
        }
      },
    },
  });
