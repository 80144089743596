
  import { defineComponent } from "vue";
  import { mapStores } from "pinia";

  import { isEqual } from "lodash";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath } from "@/helpers/utils";
  import { useApplicationStore } from "@/stores/ApplicationStore";

  export default defineComponent({
    name: "ConditionalRenderer",

    extends: BaseProcessComponent,

    inject: ["stepData"],

    data() {
      return {
        settings: {},
        conditions: {
          and: (...args: any[]): boolean => {
            return args.every((arg: any) => (arg?.condition ? this.resolveCondition(arg.condition, arg.values) : arg));
          },
          equal: (...args: any[]): boolean => {
            return args.every((arg: any) => arg === args[0]);
          },
          gt: (arg1: number, arg2: number): boolean => {
            return arg1 > arg2;
          },
          gte: (arg1: number, arg2: number): boolean => {
            return arg1 >= arg2;
          },
          lt: (arg1: number, arg2: number): boolean => {
            return arg1 < arg2;
          },
          lte: (arg1: number, arg2: number): boolean => {
            return arg1 <= arg2;
          },
          in: (arg1: any, arg2: any[]): boolean => {
            if (arg2) {
              if (typeof arg1 === "object" && !Array.isArray(arg1)) {
                for (const item of arg2) {
                  if (isEqual(item, arg1)) {
                    return true;
                  }
                }
                return false;
              } else if (typeof arg1 === "string") {
                return arg2.includes(arg1);
              }
            }

            return false;
          },
          notEqual: (...args: any[]): boolean => {
            return new Set(args).size === args.length;
          },
          or: (...args: any[]): boolean => {
            return args.some((arg: any) => (arg?.condition ? this.resolveCondition(arg.condition, arg.values) : arg));
          },
        } as any,
      };
    },

    computed: {
      ...mapStores(useApplicationStore),

      application(): any {
        return this.applicationStore.application;
      },

      renderComponents(): boolean {
        const resolvedValues: any[] = this.resolveValues(this.attributes?.settings?.values);
        const condition = this.attributes?.settings?.condition;

        if (condition in this.conditions) {
          return this.resolveCondition(condition, resolvedValues);
        }

        return false;
      },

      value: {
        get(): any {
          return this.$attrs.modelValue || {};
        },

        set(value: any): any {
          this.$emit("update:modelValue", value);
        },
      },
    },

    /**
     * TODO: Remover luego de manejar correctamente la eliminación de llaves para componentes condicionales de
     * cualquier índole
     */
    watch: {
      renderComponents(newValue: any) {
        if (!newValue) {
          if (this.parent) {
            if (this.indexPosition >= 0) {
              delete (this.stepData as any)[this.parent][this.indexPosition][this.attributes.name];
            } else {
              delete (this.stepData as any)[this.parent][this.attributes.name];
            }
          } else {
            delete (this.stepData as any)[this.attributes.name];
          }
        }
      },
    },

    methods: {
      resolveCondition(condition: string, values: any[]) {
        return this.conditions[condition](...values);
      },

      resolveValues(values: any[] = []) {
        return JSON.parse(JSON.stringify(values), (_: string, value: any) => {
          if (typeof value === "string" && value.startsWith("$")) {
            return getValueByPath(
              { application: this.application, stepData: this.stepData ?? {} },
              value.replace("$", "")
            );
          }

          return value;
        });
      },
    },
  });
