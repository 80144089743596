
  import { defineComponent } from "vue";

  import { maxLength, minLength, required } from "@/helpers/i18n-validators";
  import { vMaska } from "maska";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import { getValueByPath } from "@/helpers/utils";

  export default defineComponent({
    name: "SimpleInput",

    extends: BaseProcessComponent,

    directives: { maska: vMaska },

    validations() {
      return {
        value: this.rules(),
      };
    },

    data() {
      return {
        maskaOptions: {
          tokens: {
            "~": {
              pattern: /.*/,
              multiple: true,
              transform: (chr: string) => chr.toUpperCase(),
            },
            ":": {
              pattern: /.*/,
              multiple: true,
              transform: (chr: string) => chr.toLowerCase(),
            },
          },
        },
      };
    },

    computed: {
      textTransformMask(): string {
        const textTransformSettingsMaskMapper = {
          uppercase: "~",
          lowercase: ":",
        } as { [key: string]: string };
        return textTransformSettingsMaskMapper[this.attributes?.settings?.text_transform] ?? "";
      },

      value: {
        get() {
          return this.$attrs.modelValue || this.attributes.value || "";
        },

        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },
    },

    created() {
      if (this.attributes.hasOwnProperty("value")) {
        this.value = this.attributes.value;
        this.componentViewMode = this.viewModes.visualization;
      } else if (this.attributes.hasOwnProperty("source") && this.applicationStore.application && !this.value) {
        this.$nextTick(() => {
          this.value = getValueByPath(
            { application: this.applicationStore.application },
            this.attributes.source.replace("$", "")
          );
        });
      }
    },

    methods: {
      rules() {
        return {
          ...(this.attributes.required && { required }),
          ...(this.attributes.maxlength && { maxLength: maxLength(this.attributes.maxlength) }),
          ...(this.attributes.minlength && { minLength: minLength(this.attributes.minlength) }),
          $autoDirty: true,
        };
      },
    },
  });
