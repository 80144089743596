export default {
  roleName: {
    label: "Nombre",
    id: "roleName",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder",
  },
  roleDescription: {
    label: "Descripción",
    id: "roleDescription",
    colClasses: "col",
    headerClasses: "fs-5 text-gray-700 fw-bolder",
  },
  actions: {
    label: "Acciones",
    id: "actions",
    colClasses: "col",
    headerClasses: "text-center fs-5 text-gray-700 fw-bolder",
    cellClasses: "d-flex justify-content-center align-items-center",
  },
};
