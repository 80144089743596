import { ComponentPublicInstance } from "vue";

import BaseError from "@/core/errors/BaseError";

export function customErrorHandler(error: unknown, instance?: ComponentPublicInstance | null, info?: string) {
  if (error instanceof BaseError) {
    error.report(instance, info);
    error.render();
  } else {
    throw error;
  }
}
