import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header d-flex flex-column justify-content-center" }
const _hoisted_3 = { class: "fw-bold text-gray-800 fs-1 lh-base" }
const _hoisted_4 = { class: "fw-bold text-gray-500 fs-5" }
const _hoisted_5 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.subtitle), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_DataTable, _mergeProps({
        data: _ctx.dataTableRows,
        header: _ctx.dataTableHeaders
      }, _ctx.$attrs, {
        onOnSort: _ctx.onSort,
        class: "table table-row-dashed"
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.dataTableHeaders, (_, index) => {
          return {
            name: index,
            fn: _withCtx(({ row: data }) => [
              _createTextVNode(_toDisplayString(data[index]), 1)
            ])
          }
        })
      ]), 1040, ["data", "header", "onOnSort"])
    ])
  ]))
}