
  import { defineComponent, PropType } from "vue";

  import { keys, values } from "lodash";

  import { Component } from "@/components/base-card/CustomTypes";

  export default defineComponent({
    name: "CardRow",

    props: {
      body: {
        type: Object as PropType<{ [key: string]: Component }>,
        required: true,
      },

      config: {
        type: Object as PropType<{ [key: string]: any } | null>,
        default: () => null,
      },
    },

    data() {
      return {
        tableConfig: {} as { [key: string]: any },
      };
    },

    computed: {
      actionClasses(): string {
        return `${this.tableConfig.actions.colClasses} ${this.tableConfig.actions.cellClasses}`;
      },

      haveActions() {
        return keys(this.tableConfig).includes("actions");
      },

      items(): Component[] {
        return values(this.tableConfig)
          .filter((title: any) => title.id !== "actions")
          .map((title: any) => this.body[title.id]);
      },

      sourceIsArray(): any {
        return (source: any) => Array.isArray(source);
      },
    },

    created() {
      this.tableConfig = (this.config ?? (this.$route.meta?.viewSettings as any)?.table?.config) || {};
    },

    methods: {
      classes(item: Component): string {
        if (item?.props["cellClasses"]) {
          return `${this.tableConfig[item?.props?.id ?? ""]?.colClasses} ${item?.props?.cellClasses}`;
        }

        return `${this.tableConfig[item?.props?.id ?? ""]?.colClasses} ${
          this.tableConfig[item?.props?.id ?? ""]?.cellClasses
        }`;
      },
    },
  });
