
  import { defineComponent } from "vue";

  import baseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";

  export default defineComponent({
    name: "NestedFormContainer",

    extends: baseProcessComponent,

    computed: {
      value: {
        get() {
          return this.$attrs.modelValue || this.attributes.value || {};
        },

        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },
    },
  });
