import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d89a145"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-5 px-0 progress-info" }
const _hoisted_2 = { class: "text-gray-900 fw-bolder fs-3" }
const _hoisted_3 = { class: "progress mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "progress-bar bg-danger",
        role: "progressbar",
        style: _normalizeStyle({ width: `${_ctx.source}%` })
      }, null, 4)
    ])
  ]))
}