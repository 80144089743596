
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import Drivers from "@/helpers/clients/Drivers";
  import DriverExecutionError from "@/core/errors/DriverExecutrionError";
  import RelationalInnerSelect from "@/components/processComponents/RelationalInnerSelect.vue";

  export default defineComponent({
    name: "RelationalSelect",

    components: { RelationalInnerSelect },

    extends: BaseProcessComponent,

    emits: ["update:modelValue"],

    data() {
      return {
        lastParentValue: null as any,
        options: [] as { label: string; value: any; disabled?: boolean }[],
        overriddenAttributes: this.attributes,
      };
    },

    computed: {
      selectedValue: {
        get() {
          const newValue = this.$attrs.modelValue || this.attributes.value || null;
          this.emitChanger(newValue);
          return newValue;
        },
        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },
    },

    beforeCreate() {
      this.$emitter.on("relational-select-changed", (eventData: any) => this.reactToRelationalChanges(eventData));
    },

    async created() {
      if (!this.isReadOnly && this.attributes.settings) {
        try {
          const driversClient = new Drivers();
          this.options = [{ label: "Cargando elementos...", value: null, disabled: true }];
          const response = await driversClient.execute(
            process.env.VUE_APP_GET_CATALOGS_DRIVER,
            this.attributes.settings
          );
          if (response) {
            const items = response.data.catalogs ? response.data.catalogs[0].items : [];
            this.options = this.formatItems(items);
            this.overriddenAttributes = { ...this.attributes, options: this.options };
            if (
              this.selectedValue &&
              !this.overriddenAttributes.options.some((option: any) => option.value === this.selectedValue.codigo)
            ) {
              this.selectedValue = null;
            }
          }
        } catch (error) {
          this.options = [{ label: "Error al cargar el catálogo ", value: null, disabled: true }];
          this.overriddenAttributes = { ...this.attributes, options: this.options };
          this.selectedValue = null;
          throw new DriverExecutionError(error);
        }
      }

      setTimeout(() => {
        this.emitChanger(this.selectedValue);
      }, 5);
    },

    methods: {
      emitChanger(newValue: any) {
        const catalogs = newValue
          ? this.overriddenAttributes?.options?.find((option: any) => option.value === newValue.codigo)?.catalogs
          : [];
        this.$emitter.emit("relational-select-changed", {
          emitterName: this.attributes.name || "",
          emittedValue: {
            value: newValue,
            catalogs,
          },
        });
      },

      formatItems(items: { codigo: string; detalle: string; catalogs?: any[] }[]) {
        let result: { label: string; value: string }[] = [];

        for (const item of items) {
          let formattedItem: any = { label: item.detalle, value: item.codigo };
          if (item.hasOwnProperty("catalogs")) {
            formattedItem.catalogs = item.catalogs;
          }
          result.push(formattedItem);
        }
        return result;
      },

      getOptions(value: any) {
        return value && value.length !== 0 ? this.formatItems(value[0].items) : value;
      },

      reactToRelationalChanges(eventData: any) {
        this.$nextTick(() => {
          if (this.attributes.hasOwnProperty("parent") && eventData.emitterName === this.attributes.parent) {
            this.overriddenAttributes = {
              ...this.attributes,
              options: this.getOptions(eventData.emittedValue.catalogs),
            };
            if (
              (!eventData.emittedValue.value && this.selectedValue) ||
              (this.lastParentValue && this.lastParentValue?.codigo !== eventData.emittedValue.value?.codigo)
            ) {
              this.selectedValue = null;
            }
            this.lastParentValue = eventData.emittedValue.value;
          }
        });
      },
    },
  });
