
  import { defineComponent } from "vue";

  import Popper from "vue3-popper";

  export default defineComponent({
    name: "CardContextMenu",

    components: {
      Popper,
    },

    props: {
      show: {
        type: Boolean,
        default: () => true,
      },
    },
  });
