
  import { defineComponent } from "vue";

  import Multiselect from "@vueform/multiselect";

  export default defineComponent({
    name: "VueMultiSelect",

    components: { Multiselect },

    props: {
      noResultsText: {
        type: String,
        default: () => "No se encontraron coincidencias",
      },

      noOptionsText: {
        type: String,
        default: () => "No hay opciones a mostrar",
      },

      placeholder: {
        type: String,
        default: () => "Seleccione...",
      },

      searchable: {
        type: Boolean,
        default: () => true,
      },
    },

    methods: {
      showOptions() {
        (this.$refs.multiselect as any).open();
      },
    },

    computed: {
      value: {
        get(): any {
          return this.$attrs.modelValue;
        },

        set(value: any) {
          this.$emit("update:modelValue", value);
        },
      },
    },
  });
