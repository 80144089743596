import { AxiosError } from "axios";

import Router from "@/router";

import ToastError from "@/core/errors/ToastError";
import { useUserStore } from "@/stores/UserStore";
import { swalWarning } from "@/helpers/sweetalert";

export default class BaseClientError extends ToastError {
  constructor(error: unknown, clientLabel?: string, initialMessage?: string) {
    let message: string = initialMessage ?? "";
    if (error instanceof AxiosError) {
      const statusError: any = (error as any).response.status;
      message = `[HTTP ${error.code}]: `;

      if (statusError === 401) {
        const userStore: any = useUserStore();
        userStore.clear();

        swalWarning({
          customClass: { container: "session-expired-notification" },
          confirmButtonText: "Iniciar sesión",
          title: "Su sesión ha expirado, por favor inicie sesión de nuevo",
          allowEscapeKey: false,
        }).then(async (response) => {
          if (response.isConfirmed) {
            await Router.push({
              name: "Login",
              query: { redirect: Router.currentRoute.value.fullPath, redirect_cause: "unauthenticated" },
            });
          }
        });
      } else if (statusError === 403) {
        message += "No tiene permisos para acceder a este módulo";
      } else if (statusError === 405) {
        const option = (error as any).response.config.method === "put" ? "modificar" : "eliminar";
        message += `No se puede ${option}. Ya existen datos asociados`;
      } else if (statusError >= 500) {
        message += error.response?.data
          ? JSON.stringify(error.response.data)
          : `${error.message}, ${error.request.responseURL}`;
      } else {
        message += error.message;
      }
    }

    super(message || `Ha ocurrido un error desconocido con ${clientLabel}`);
  }
}
