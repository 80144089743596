import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { keys } from "lodash";

import brokeragesTableConfig from "@/core/config/BrokeragesTableConfig";
import policiesTableConfig from "@/core/config/PoliciesTableConfig";
import quotesTableConfig from "@/core/config/QuotesTableConfig";
import rolesTableConfig from "@/core/config/RolesTableConfig";
import usersTableConfig from "@/core/config/UsersTableConfig";
import { useUserStore } from "@/stores/UserStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/iniciar-sesion",
    component: () => import("@/layouts/MasterLayout.vue"),
    children: [
      {
        path: "polizas",
        name: "Polizas",
        component: () => import("@/views/Inbox.vue"),
        meta: {
          inboxType: "policies",
          applicationTypeId: process.env.VUE_APP_POLICY_APPLICATION_TYPE_TAG,
          viewSettings: {
            searchBar: {
              config: () => import("@/core/config/PoliciesSearchBarConfig"),
            },
            table: {
              actionPermissions: [["applications-management.applications.read"]],
              config: policiesTableConfig,
            },
            card: {
              type: "PolicyCard",
            },
          },
          pluralTitle: "Pólizas",
          singularTitle: "Póliza",
          requiredPermissions: [["applications-management.applications.search"]],
          requiresAuth: true,
        },
      },
      {
        path: "cotizaciones",
        name: "Cotizaciones",
        component: () => import("@/views/Inbox.vue"),
        meta: {
          inboxType: "quotes",
          applicationTypeId: process.env.VUE_APP_QUOTE_APPLICATION_TYPE_TAG,
          viewSettings: {
            searchBar: {
              config: () => import("@/core/config/QuotesSearchBarConfig"),
            },
            table: {
              actionPermissions: [
                [
                  "applications-management.applications.read",
                  "applications-management.applications.update",
                  "applications-management.applications.delete",
                ],
              ],
              config: quotesTableConfig,
            },
            card: {
              type: "QuoteCard",
            },
          },
          pluralTitle: "Cotizaciones",
          singularTitle: "Cotización",
          requiredPermissions: [["applications-management.applications.search"]],
          requiresAuth: true,
        },
      },
      {
        path: "/cotizaciones/:processId/crear",
        name: "CreateApplication",
        component: () => import("@/views/ApplicationCreation.vue"),
        meta: {
          mode: "create",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Cotizaciones",
              to: {
                name: "Cotizaciones",
              },
            },
            {
              text: "Crear cotización",
            },
          ],
          requiredPermissions: [
            ["processes-management.processes.read"],
            ["applications-management.applications.create"],
            ["applications-management.application-steps.create"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/cotizaciones/:id/pasos/actual/modificar",
        name: "EditCurrentStep",
        component: () => import("@/views/ApplicationRender.vue"),
        meta: {
          mode: "modification",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Cotizaciones",
              to: {
                name: "Cotizaciones",
              },
            },
            "addStepName",
          ],
          requiredPermissions: [
            ["applications-management.applications.read"],
            ["applications-management.applications.update"],
            ["applications-management.application-steps.search"],
            ["applications-management.application-steps.read"],
            ["applications-management.application-steps.update"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/cotizaciones/:id/pasos/:stepId/modificar",
        name: "EditStep",
        component: () => import("@/views/ApplicationRender.vue"),
        meta: {
          mode: "modification",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Cotizaciones",
              to: {
                name: "Cotizaciones",
              },
            },
            "addStepName",
          ],
          requiredPermissions: [
            ["processes-management.processes.read"],
            ["applications-management.applications.read"],
            ["applications-management.application-steps.update"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/cotizaciones/:id/pasos/actual/consultar",
        name: "ReadCurrentStep",
        component: () => import("@/views/ApplicationRender.vue"),
        meta: {
          mode: "visualization",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Cotizaciones",
              to: {
                name: "Cotizaciones",
              },
            },
            "addStepName",
          ],
          requiredPermissions: [
            ["applications-management.applications.read"],
            ["applications-management.application-steps.search"],
            ["applications-management.application-steps.read"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/cotizaciones/:id/pasos/:stepId/consultar",
        name: "ReadStep",
        component: () => import("@/views/ApplicationRender.vue"),
        meta: {
          mode: "visualization",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Cotizaciones",
              to: {
                name: "Cotizaciones",
              },
            },
            "addStepName",
          ],
          requiredPermissions: [
            ["processes-management.processes.read"],
            ["applications-management.applications.read"],
            ["applications-management.application-steps.read"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/polizas/:id/pasos/:stepId/consultar",
        name: "ReadPolicy",
        component: () => import("@/views/ApplicationRender.vue"),
        meta: {
          singularTitle: "Póliza",
          mode: "visualization",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Pólizas",
              to: {
                name: "Polizas",
              },
            },
            "addStepName",
          ],
          requiredPermissions: [
            ["processes-management.processes.read"],
            ["applications-management.applications.read"],
            ["applications-management.application-steps.read"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/corredurias",
        name: "Brokerages",
        component: () => import("@/views/Brokerages.vue"),
        meta: {
          singularTitle: "Correduría",
          pluralTitle: "Corredurías",
          viewSettings: {
            table: {
              actionPermissions: [["categories-management.tags.read", "categories-management.tags.update"]],
              config: brokeragesTableConfig,
            },
            card: {
              type: "BrokerageCard",
            },
          },
          requiredPermissions: [["categories-management.tags.read"], ["categories-management.tags.search"]],
          requiresAuth: true,
        },
      },
      {
        path: "/corredurias/crear",
        name: "CreateBrokerage",
        component: () => import("@/views/BrokerageCreateUpdate.vue"),
        meta: {
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Corredurías",
              to: {
                name: "Brokerages",
              },
            },
            {
              text: "Crear correduría",
            },
          ],
          requiredPermissions: [
            ["categories-management.tags.create"],
            ["categories-management.tags.update"],
            ["roles-management.roles.create"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "/corredurias/:brokerageId/modificar",
        name: "UpdateBrokerage",
        component: () => import("@/views/BrokerageCreateUpdate.vue"),
        meta: {
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Corredurías",
              to: {
                name: "Brokerages",
              },
            },
          ],
          requiredPermissions: [
            ["categories-management.tags.read"],
            ["categories-management.tags.search"],
            ["categories-management.tags.update"],
            ["roles-management.roles.read"],
            ["roles-management.roles.search"],
            ["roles-management.roles.update"],
          ],
          requiresAuth: true,
        },
      },
      {
        path: "no-access",
        name: "NoAccess",
        component: () => import("@/views/NoAccess.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: () => import("@/views/PageNotFound.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/cotizaciones/:id/pasos/finalizar/modificar",
        name: "QuotedFinished",
        component: () => import("@/views/QuoteFinished.vue"),
        meta: {
          mode: "modification",
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Cotizaciones",
              to: {
                name: "Cotizaciones",
              },
            },
            {
              text: "Crear cotización",
            },
          ],
          requiredPermissions: [
            ["processes-management.processes.read"],
            ["applications-management.applications.read"],
            ["applications-management.application-steps.read", "applications-management.application-steps.update"],
          ],
          requiresAuth: true,
        },
      },
      {
        name: "Roles",
        path: "/roles",
        component: () => import("@/views/Roles.vue"),
        meta: {
          inboxType: "roles",
          requiredPermissions: [["roles-management.roles.search", "roles-management.roles.read"]],
          requiresAuth: true,
          singularTitle: "Rol",
          pluralTitle: "Roles",
          viewSettings: {
            table: {
              actionPermissions: [
                ["roles-management.roles.read", "roles-management.roles.update", "roles-management.roles.delete"],
              ],
              config: rolesTableConfig,
            },
            card: {
              type: "RoleCard",
            },
          },
        },
      },
      {
        path: "/roles/crear",
        name: "createRole",
        component: () => import("@/views/RoleCreationModification.vue"),
        meta: {
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Roles",
              to: {
                name: "Roles",
              },
            },
            {
              text: "Crear rol",
            },
          ],
          requiredPermissions: [["roles-management.roles.create"]],
          requiresAuth: true,
        },
      },
      {
        path: "/roles/:roleId/modificar",
        name: "updateRole",
        component: () => import("@/views/RoleCreationModification.vue"),
        meta: {
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Roles",
              to: {
                name: "Roles",
              },
            },
            {
              text: "Modificando rol",
            },
          ],
          requiredPermissions: [["roles-management.roles.read", "roles-management.roles.update"]],
          requiresAuth: true,
        },
      },
      {
        name: "Users",
        path: "/usuarios",
        component: () => import("@/views/Users.vue"),
        meta: {
          inboxType: "users",
          requiredPermissions: [["users-management.users.read", "users-management.users.search"]],
          requiresAuth: true,
          singularTitle: "Usuario",
          pluralTitle: "Usuarios",
          viewSettings: {
            table: {
              actionPermissions: [["users-management.users.update"]],
              config: usersTableConfig,
            },
            card: {
              type: "UserCard",
            },
          },
        },
      },
      {
        path: "usuarios/:userId/roles/modificar",
        name: "userPermissionsModification",
        component: () => import("@/views/UserPermissionsModification.vue"),
        meta: {
          breadcrumbs: [
            {
              icon: "home",
              tooltipText: "Usuarios",
              to: {
                name: "Users",
              },
            },
            {
              text: "Modificando permisos",
            },
          ],
          requiredPermissions: [["users-management.users.read", "users-management.users.update"]],
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/iniciar-sesion",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  const userStore = useUserStore();

  if (to.name === "Login" && keys(userStore.user || {}).length && userStore.isTokenValid()) {
    return { name: "Cotizaciones" };
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userStore.isTokenValid()) {
      const cause: string = !userStore.token ? "unauthenticated" : "token-expired";
      return { name: "Login", query: { redirect: to.path, redirect_cause: cause } };
    }

    if (
      to.name !== "NoAccess" &&
      to.name !== "PageNotFound" &&
      !userStore.can((to.meta.requiredPermissions as string[][]) || [])
    ) {
      return { name: "NoAccess" };
    }
  }
});

export default router;
