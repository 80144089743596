
  import { defineComponent } from "vue";

  import BaseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";
  import FileUpload from "@/components/processComponents/FileUpload.vue";
  import SimpleInput from "@/components/processComponents/SimpleInput.vue";
  import SimpleLabel from "@/components/processComponents/SimpleLabel.vue";

  export default defineComponent({
    name: "FileUploadForm",

    extends: BaseProcessComponent,

    components: {
      FileUpload,
      SimpleInput,
      SimpleLabel,
    },

    data() {
      return {
        data: {
          document: {
            name: "",
            comments: "",
            document_id: "",
            content_type: "",
          },
          name: "",
          comments: "",
        },
      };
    },

    watch: {
      data: {
        handler(newValue, oldValue) {
          const mergedValues = { ...oldValue, ...newValue };
          const response = {
            name: mergedValues.name ?? mergedValues.document?.name,
            comments: mergedValues.comments ?? mergedValues.document?.comments,
            document_id: mergedValues.document?.document_id,
            content_type: mergedValues.document?.content_type,
          };

          this.$emit("update:modelValue", response);
        },
        deep: true,
      },
    },

    created() {
      if (this.$attrs.modelValue || this.attributes.value) {
        const value = this.$attrs.modelValue || this.attributes.value;
        this.data = {
          name: value.name,
          comments: value.comments,
          document: {
            name: value.name,
            comments: value.comments,
            content_type: value.content_type,
            document_id: value.document_id,
          },
        };
      }
    },
  });
